// @ts-expect-error - No types for this lib
import FitParser from "fit-file-parser";
import dayjs from "dayjs";
import { DiveSample, SAMPLE_RATE } from "~/config/dive-log";
import { WaterType } from "~/constants/enums";

// Todo: use Zod to validate the data...
export const garminParser = async ({ file }: { file: File }) => {
  const fitParser = new FitParser({
    force: true,
    lengthUnit: "m",
    temperatureUnit: "celsius",
    elapsedRecordField: true,
    mode: "list",
  });

  const bufferedFile = await file.arrayBuffer();

  let sourceData: unknown;

  // @ts-expect-error - No types for this lib
  fitParser.parse(bufferedFile, (error, data) => {
    if (error) {
      console.error("error", error);
    } else {
      sourceData = data;
    }
  });

  const {
    // Note: bottom_time is different to end_time - start_time
    dive_summary: { max_depth },
    dive_settings: { water_type },
    records,
    tank_summaries,
    tank_updates,
  } = sourceData as {
    dive_summary: { max_depth: number };
    dive_settings: { water_type: string };
    records: {
      temperature: number;
      timestamp: Date;
      depth: number;
      heart_rate: number;
      elapsed_time: number;
      cns_load: number;
      ndl_time: number;
    }[];
    tank_summaries: {
      sensor: number;
      start_pressure: number;
      end_pressure: number;
    }[];
    tank_updates: {
      timestamp: Date;
      sensor: number;
      pressure: number;
    }[];
  };

  let min_water_temp = Infinity;
  let max_water_temp = -Infinity;
  const startTime = records[0].timestamp;
  let endTime = new Date();

  const dive_samples: DiveSample[] = [];

  records.forEach(
    ({
      temperature,
      timestamp,
      depth,
      heart_rate,
      elapsed_time,
      cns_load,
      ndl_time,
    }) => {
      if (temperature < min_water_temp) {
        min_water_temp = temperature;
      }

      if (temperature > max_water_temp) {
        max_water_temp = temperature;
      }

      endTime = timestamp;

      const lastSample = dive_samples[dive_samples.length - 1];
      const timeDiff = lastSample
        ? dayjs(timestamp).diff(dayjs(lastSample.timestamp), "seconds")
        : Infinity;

      if (timeDiff >= SAMPLE_RATE) {
        // find the tank update that is closest to the timestamp
        const tankUpdate = tank_updates?.reduce((prev, curr) => {
          if (!prev.timestamp) {
            return curr;
          }

          return Math.abs(curr.timestamp.getTime() - timestamp.getTime()) <
            Math.abs(prev.timestamp.getTime() - timestamp.getTime())
            ? curr
            : prev;
        }, {} as { timestamp: Date; pressure: number });

        dive_samples.push({
          depth: depth / 1000,
          water_temp: temperature,
          timestamp,
          time: dayjs(timestamp).format("HH:mm:ss"),
          elapsed_time,
          heart_rate,
          cns: cns_load,
          no_deco_time: ndl_time,
          tank_pressure: tankUpdate.pressure,
        });
      }
    }
  );

  const dive_duration = dayjs(endTime).diff(dayjs(startTime), "minutes");
  const entry_date = dayjs(startTime).format("YYYY-MM-DD");
  const entry_time = dayjs(startTime).format("HH:mm");

  return {
    min_water_temp,
    max_water_temp,
    entry_date,
    entry_time,
    dive_duration,
    max_depth: max_depth / 1000,
    water_type: water_type as WaterType,
    dive_samples,
    pressure_start: tank_summaries[0]?.start_pressure,
    pressure_end: tank_summaries[0]?.end_pressure,
  };
};
