import { FormEvent, useCallback, useEffect, useRef, useState } from "react";
import { useForm } from "@mantine/form";
import { IconBuildingStore } from "@tabler/icons-react";
import { useFetcher, useNavigate } from "@remix-run/react";
import { Title } from "@mantine/core";
import { Form } from "~/components/_common/form/Form/Form";
import { ModalTypes, MODALS } from "~/config/modals";
import {
  DiveCompanyErrors,
  DiveCompanyFormInputs,
  diveCompanyFormValidator,
  COMPANY_TYPES,
  CONSERVATION_TYPES,
} from "~/config/organisation";
import { WizardModal } from "~/components/AddCreateWizard/WizardModal/WizardModal";
import { Container } from "~/components/_common/form/Container/Container";
import { Row } from "~/components/_common/Row/Row";
import { Button } from "~/components/_common/Button/Button";
import { TextInput } from "~/components/_common/form/TextInput/TextInput";
import { ErrorText } from "~/components/_common/form/ErrorText/ErrorText";
import { CountrySearch } from "~/components/CountrySearch/CountrySearch";
import { LocationSearchClient } from "~/components/LocationSearch/LocationSearch";
import { NativeSelect } from "~/components/_common/form/NativeSelect/NativeSelect";
import { action as upsertAction } from "~/routes/_api.upsert-dive-company";
import { Textarea } from "~/components/_common/form/Textarea/Textarea";
import { ImageUpload } from "~/components/_common/ImageUpload/ImageUpload";
import type { ModalData, OutletContext } from "~/types/shared";
import {
  HERO_WIDTH_MEDIUM,
  HERO_WIDTH_SMALL,
  IMAGE_TYPES,
} from "~/config/images";
import { ResponsiveImage } from "~/components/_common/ResponsiveImage/ResponsiveImage";
import { ORGANISATION } from "~/config/routes";
import { MultiSelect } from "~/components/_common/form/MultiSelect/MultiSelect";
import {
  CORAL_ACTIVITES,
  MARINE_ACTIVITES,
  OTHER_ACTIVITES,
} from "~/config/dive-log";
import { FIELDS } from "~/components/modals/DiveLogModal/DiveLogModal";
import { showNotication } from "~/config/notifications";

type newCountryValues = {
  country: string;
  country_id: string;
  country_code: string;
};

type CommonProps = {
  initialValues?: Partial<DiveCompanyFormInputs> | null;
  newCountryValues: newCountryValues | null;
  modalType: ModalTypes;
  outletContext: OutletContext;
};

function ExtraFields({
  form,
  errors,
  outletContext,
  initialValues,
}: {
  form: ReturnType<typeof useForm>;
  errors: DiveCompanyErrors;
  outletContext: OutletContext;
  initialValues?: Partial<DiveCompanyFormInputs> | null;
}) {
  const { companyHero, companyLogo } = initialValues ?? {};
  const { setFieldValue, values } = form;
  const { company_type, conservation_type } = values;

  const isConservation = company_type === COMPANY_TYPES.CONSERVATION;

  return (
    <>
      {isConservation ? (
        <>
          <NativeSelect
            name="conservation_type"
            label="Conservation organisation type"
            {...form.getInputProps("conservation_type")}
            data={[
              { label: "Select conservation organisation type...", value: "" },
              { label: "NGO / Non-profit", value: CONSERVATION_TYPES.NGO },
              { label: "For-profit", value: CONSERVATION_TYPES.FOR_PROFIT },
            ]}
          />
          {conservation_type === CONSERVATION_TYPES.NGO ? (
            <TextInput
              name="charity_number"
              label="Charity number"
              {...form.getInputProps("charity_number")}
            />
          ) : null}
          <MultiSelect
            name={FIELDS.conservation_activities}
            label="Conservation activities"
            value={form.values.conservation_activities}
            onChange={(v) => setFieldValue(FIELDS.conservation_activities, v)}
            placeholder="Select activities..."
            data={[
              {
                group: "Coral",
                items: CORAL_ACTIVITES,
              },
              {
                group: "Marine life",
                items: MARINE_ACTIVITES,
              },
              {
                group: "Other",
                items: OTHER_ACTIVITES,
              },
            ]}
          />
        </>
      ) : null}
      <ImageUpload
        key="uno"
        hasBorder
        outletContext={outletContext}
        label="Hero"
        metadata={{
          image_type: IMAGE_TYPES.HERO,
          company_id: form.values.id,
        }}
        currentImage={
          <ResponsiveImage
            alt=""
            image={companyHero}
            mediumMinWidth={HERO_WIDTH_SMALL}
            largeMinWidth={HERO_WIDTH_MEDIUM}
          />
        }
      />
      <ImageUpload
        key="dos"
        hasBorder
        outletContext={outletContext}
        label="Logo"
        metadata={{
          image_type: IMAGE_TYPES.LOGO,
          company_id: form.values.id,
        }}
        currentImage={
          companyLogo ? <img src={companyLogo?.original} alt="" /> : null
        }
      />
      <Textarea
        {...form.getInputProps("description")}
        name="description"
        label="Description"
        placeholder="Enter company description..."
        autosize
        minRows={2}
        maxRows={4}
        error={errors.description}
      />
      <TextInput
        name="web_url"
        label="Web URL"
        placeholder="www.example.com"
        {...form.getInputProps("web_url")}
        error={errors.web_url}
      />
      <TextInput
        name="instagram_url"
        label="Instagram URL"
        placeholder="www.instagram.com/scuba-mania"
        {...form.getInputProps("instagram_url")}
        error={errors.instagram_url}
      />
      <TextInput
        name="twitter_url"
        label="X / Twitter URL"
        placeholder="www.x.com/scuba-mania"
        {...form.getInputProps("twitter_url")}
        error={errors.twitter_url}
      />
      <TextInput
        name="facebook_url"
        label="Facebook URL"
        placeholder="www.facebook.com/scuba-mania"
        {...form.getInputProps("facebook_url")}
        error={errors.facebook_url}
      />
      <TextInput
        name="youtube_url"
        label="Youtube URL"
        placeholder="www.youtube.com/scuba-mania"
        {...form.getInputProps("youtube_url")}
        error={errors.youtube_url}
      />
    </>
  );
}

function FormStuff({
  newCountryValues,
  handleGoBack,
  handleCheckGoBack,
  showLeaveWarning,
  setShowLeaveWarning,
  handleClose,
  handleCheckClose,
  setIsFormDirty,
  prevModal,
  initialValues,
  modalType,
  outletContext,
}: CommonProps & {
  handleGoBack: (count?: number, data?: ModalData) => void;
  handleCheckGoBack: (count?: number, data?: ModalData) => void;
  showLeaveWarning: "close" | "go-back" | null;
  setShowLeaveWarning: (value: "close" | "go-back" | null) => void;
  handleClose: () => void;
  handleCheckClose: () => void;
  setIsFormDirty: (value: boolean) => void;
  prevModal: ModalTypes;
}) {
  const navigate = useNavigate();
  const formRef = useRef<HTMLFormElement | null>(null);
  const form = useForm<Partial<DiveCompanyFormInputs>>({
    initialValues: {
      company_type: "",
      country_id: "",
      country_code: initialValues?.alpha_2 ?? "",
      location: "",
      place_id: "",
      latitude: "",
      longitude: "",
      conservation_type: "",
      conservation_activities: [],
      ...newCountryValues,
      ...initialValues,
      company_name:
        initialValues?.company_name ??
        initialValues?.conservation_organisation_name ??
        "",
    },
    validate: diveCompanyFormValidator,
  });

  const originalHeroImageId = useRef(initialValues?.companyHero?.id);
  const originalLogoImageId = useRef(initialValues?.companyLogo?.id);

  const upsertFetcher = useFetcher<typeof upsertAction>();
  const { errors } = upsertFetcher.data ?? { errors: {} };
  const allErrors: DiveCompanyErrors =
    upsertFetcher.state === "submitting" ? {} : { ...errors, ...form.errors };
  const hasErrors = Object.keys(allErrors).length > 0;

  const isFormDirty = form.isDirty();
  const { setFieldValue } = form;

  useEffect(() => {
    setIsFormDirty(isFormDirty);
  }, [setIsFormDirty, isFormDirty]);

  useEffect(() => {
    // Make the form dirty if the image has changed
    const newHero = initialValues?.companyHero?.id;
    if (originalHeroImageId.current !== newHero && initialValues?.companyHero) {
      setFieldValue("companyHero", initialValues.companyHero);
    }
  }, [initialValues, setFieldValue]);

  useEffect(() => {
    // Make the form dirty if the image has changed
    const newLogo = initialValues?.companyLogo?.id;
    if (originalLogoImageId.current !== newLogo && initialValues?.companyLogo) {
      setFieldValue("companyLogo", initialValues.companyLogo);
    }
  }, [initialValues, setFieldValue]);

  const handleSetCountryFields = useCallback(
    (countryId: string, countryCode: string) => {
      if (initialValues?.country_id !== countryId) {
        setFieldValue("country_id", countryId);
        setFieldValue("country_code", countryCode);
      }
    },
    [setFieldValue, initialValues]
  );

  const handleResetCountryFields = useCallback(() => {
    setFieldValue("country_code", "");
    setFieldValue("country_id", "");
  }, [setFieldValue]);

  const handleSubmit = (e?: FormEvent) => {
    e?.preventDefault();
    form.clearErrors();

    const { hasErrors: localErrors } = form.validate();

    if (!localErrors && formRef?.current) {
      const formData = new FormData(formRef.current);

      if (modalType === MODALS.ORGANISATION_EDIT) {
        formData.append("id", initialValues?.id ?? "");
      }

      formData.set(
        "conservation_activities",
        JSON.stringify(form.values.conservation_activities ?? [])
      );

      upsertFetcher.submit(formData, {
        method: "post",
        action: "/upsert-dive-company",
      });
    }
  };

  useEffect(
    function onSuccess() {
      if (
        upsertFetcher.state === "idle" &&
        upsertFetcher.data?.id &&
        form.values.company_name &&
        form.values.company_type &&
        !hasErrors &&
        isFormDirty
      ) {
        const message = `Dive company successfully ${
          modalType === MODALS.ORGANISATION_EDIT ? "updated" : "created"
        }`;
        showNotication(message, "success", true);

        form.reset();

        if (prevModal === MODALS.ADD_CREATE) {
          handleClose();
          navigate(`${ORGANISATION}/${upsertFetcher.data.id}`);
        } else {
          const data = form.values.conservation_organisation_name
            ? {
                conservation_organisation_name: upsertFetcher.data.companyName,
              }
            : {
                company_name: upsertFetcher.data.companyName,
              };
          handleGoBack(1, data);
        }
      }
    },
    [
      upsertFetcher.state,
      upsertFetcher.data,
      isFormDirty,
      form,
      handleGoBack,
      handleClose,
      setIsFormDirty,
      hasErrors,
      prevModal,
      modalType,
      navigate,
    ]
  );

  if (showLeaveWarning) {
    return (
      <Container>
        <Title
          order={2}
          size="h3"
          style={{
            textAlign: "center",
            marginBottom: 20,
          }}
        >
          You have unsaved changes. Are you sure you want to{" "}
          {showLeaveWarning === "close" ? "leave" : "go back"}?
        </Title>
        <Row>
          <Button
            variant="light"
            style={{ flex: 1 }}
            onClick={() => {
              setShowLeaveWarning(null);
            }}
            disabled={upsertFetcher.state === "submitting"}
          >
            No thanks
          </Button>
          <Button
            variant="default"
            style={{ flex: 1 }}
            onClick={() => {
              if (showLeaveWarning === "go-back") {
                handleGoBack();
              } else {
                handleClose();
              }
            }}
            loading={upsertFetcher.state === "submitting"}
            disabled={upsertFetcher.state === "submitting"}
          >
            Yes please
          </Button>
        </Row>
      </Container>
    );
  }

  return (
    <Form onSubmit={handleSubmit} ref={formRef}>
      <Container
        footer={
          <>
            <Row flexEnd>
              {modalType === MODALS.ORGANISATION_EDIT ? (
                <Button
                  variant="outline"
                  disabled={upsertFetcher.state === "submitting"}
                  onClick={() => handleCheckClose()}
                >
                  Cancel
                </Button>
              ) : (
                <Button
                  variant="transparent"
                  mr="auto"
                  disabled={upsertFetcher.state === "submitting"}
                  onClick={() => handleCheckGoBack()}
                >
                  {prevModal ? "Go back" : "Cancel"}
                </Button>
              )}
              <Button
                variant="default"
                loading={upsertFetcher.state === "submitting"}
                disabled={upsertFetcher.state === "submitting" || !isFormDirty}
                onClick={() => {
                  handleSubmit();
                }}
              >
                Save
              </Button>
            </Row>
            <ErrorText>{allErrors.misc}</ErrorText>
          </>
        }
      >
        <NativeSelect
          required
          name="company_type"
          label="Type"
          {...form.getInputProps("company_type")}
          data={[
            { label: "Select company / organisation type", value: "" },
            { label: "Dive centre", value: COMPANY_TYPES.CENTRE },
            { label: "Dive operator", value: COMPANY_TYPES.OPERATOR },
            { label: "Liveaboard", value: COMPANY_TYPES.LIVEABOARD },
            { label: "Resort", value: COMPANY_TYPES.RESORT },
            { label: "Conservation", value: COMPANY_TYPES.CONSERVATION },
          ]}
          error={allErrors.company_type}
        />
        <TextInput
          required
          name="company_name"
          label="Name"
          placeholder="Enter the name"
          {...form.getInputProps("company_name")}
          error={allErrors.company_name}
        />
        <CountrySearch
          value={form.values.country ?? ""}
          onChange={(v) =>
            form.setValues({
              country: v,
            })
          }
          onSetCountryFields={handleSetCountryFields}
          onResetCountryFields={handleResetCountryFields}
          countryIdProps={{
            ...form.getInputProps("country_id"),
            name: "country_id",
          }}
          error={allErrors.country_id}
        />
        <LocationSearchClient
          required
          disabled={!form.values.country_code}
          filter={form.values.country_code}
          locationInputProps={{
            ...form.getInputProps("location"),
            label: "Location",
            name: "location",
            placeholder: "Enter the location",
          }}
          latitudeInputProps={{
            ...form.getInputProps("latitude"),
            name: "latitude",
          }}
          longitudeInputProps={{
            ...form.getInputProps("longitude"),
            name: "longitude",
          }}
          placeIdInputProps={{
            ...form.getInputProps("place_id"),
            name: "place_id",
          }}
          error={allErrors.location || allErrors.place_id}
        />
        {modalType === MODALS.ORGANISATION_EDIT && (
          <ExtraFields
            form={form}
            errors={allErrors}
            outletContext={outletContext}
            initialValues={initialValues}
          />
        )}
      </Container>
    </Form>
  );
}

export function CreateDiveCompany({
  modalHistory,
  onClose,
  goBack,
  newCountryValues,
  initialValues,
  modalType,
  outletContext,
}: CommonProps & {
  modalHistory: ModalTypes[];
  onClose: () => void;
  goBack: (count?: number, data?: ModalData) => void;
  newCountryValues: newCountryValues | null;
  modalType: ModalTypes;
}) {
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [showLeaveWarning, setShowLeaveWarning] = useState<
    "close" | "go-back" | null
  >(null);

  const prevModal = modalHistory[modalHistory.length - 2];

  const handleClose = () => {
    onClose();
    setIsFormDirty(false);
    setTimeout(() => {
      setShowLeaveWarning(null);
    }, 200);
  };

  const handleCheckClose = () => {
    if (!isFormDirty) {
      handleClose();
    } else {
      setShowLeaveWarning("close");
    }
  };

  const handleGoBack = (count?: number, data?: ModalData) => {
    goBack(count, data);

    setTimeout(() => {
      setShowLeaveWarning(null);
    }, 200);
  };

  const handleCheckGoBack = (count?: number, data?: ModalData) => {
    if (!isFormDirty) {
      handleGoBack(count, data);
    } else {
      setShowLeaveWarning("go-back");
    }
  };

  return (
    <WizardModal
      modalHistory={modalHistory}
      modalType={modalType}
      onClose={handleCheckClose}
      titleIcon={<IconBuildingStore />}
      title={
        modalType === MODALS.CREATE_ORGANISATION
          ? "Add a company / organisation"
          : "Edit a company / organisation"
      }
      withCloseButton={!showLeaveWarning}
      isShort={Boolean(showLeaveWarning)}
    >
      <FormStuff
        newCountryValues={newCountryValues}
        handleGoBack={handleGoBack}
        handleCheckGoBack={handleCheckGoBack}
        handleClose={handleClose}
        handleCheckClose={handleCheckClose}
        showLeaveWarning={showLeaveWarning}
        setShowLeaveWarning={setShowLeaveWarning}
        setIsFormDirty={setIsFormDirty}
        prevModal={prevModal}
        initialValues={initialValues}
        modalType={modalType}
        outletContext={outletContext}
      />
    </WizardModal>
  );
}
