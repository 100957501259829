import { NativeSelect as MantineNativeSelect } from "@mantine/core";
import * as classes from "./NativeSelect.css";

export function NativeSelect(
  props: React.ComponentProps<typeof MantineNativeSelect>
) {
  return (
    <MantineNativeSelect
      classNames={{
        input: classes.input,
      }}
      {...props}
    />
  );
}
