import { DiveSample, SAMPLE_RATE } from "~/config/dive-log";
import { fahrenheitToCelsius, feetToMetres } from "../conversions";
import dayjs from "dayjs";

export const shearwaterParser = (data: {
  dive: {
    diveLog: {
      imperialUnits: boolean;
      startDate: string;
      endDate: string;
      maxDepth: number;
      maxTime: number;
      diveLogRecords: {
        diveLogRecord: {
          currentDepth: number;
          currentTime: number;
          waterTemp: number;
          averagePPO2: number;
        }[];
      };
    };
  };
}) => {
  const {
    dive: {
      diveLog: {
        imperialUnits,
        maxDepth,
        maxTime,
        startDate,
        diveLogRecords: { diveLogRecord },
      },
    },
  } = data;

  let min_water_temp = Infinity;
  let max_water_temp = -Infinity;
  const max_depth = imperialUnits ? feetToMetres(maxDepth) : maxDepth;
  const dive_samples: DiveSample[] = [];

  diveLogRecord.forEach(
    ({ currentDepth, currentTime, waterTemp, averagePPO2 }) => {
      const elapsed_time = currentTime / 1000;
      const lastSample = dive_samples[dive_samples.length - 1];
      const timeDiff = lastSample?.elapsed_time
        ? elapsed_time - lastSample.elapsed_time
        : Infinity;

      const water_temp = imperialUnits
        ? fahrenheitToCelsius(waterTemp)
        : waterTemp;

      if (timeDiff >= SAMPLE_RATE) {
        dive_samples.push({
          depth: imperialUnits ? feetToMetres(currentDepth) : currentDepth,
          water_temp,
          elapsed_time,
          po2: averagePPO2,
        });
      }

      if (water_temp < min_water_temp) {
        min_water_temp = water_temp;
      }

      if (water_temp > max_water_temp) {
        max_water_temp = water_temp;
      }
    }
  );

  const entry_date = dayjs(startDate).format("YYYY-MM-DD");
  const entry_time = dayjs(startDate).format("HH:mm");

  return {
    dive_samples,
    min_water_temp,
    max_water_temp,
    max_depth,
    entry_date,
    entry_time,
    dive_duration: Math.round(maxTime / 60),
  };
};
