import { IconArrowRight, IconRuler3 } from "@tabler/icons-react";
import { WizardModal } from "~/components/AddCreateWizard/WizardModal/WizardModal";
import { UnitToggles } from "~/components/_common/UnitToggles/UnitToggles";
import { ModalTypes, MODALS } from "~/config/modals";
import { Row } from "~/components/_common/Row/Row";
import { Button } from "~/components/_common/Button/Button";
import { OutletContext, UserProfile } from "~/types/shared";

export function UnitsModal({
  modalHistory,
  setCurrentModal,
  outletContext,
  userProfile,
}: {
  modalHistory: ModalTypes[];
  setCurrentModal: (modal: ModalTypes | null) => void;
  outletContext: OutletContext;
  userProfile: UserProfile;
}) {
  const { session } = outletContext;
  const isOnboarding = session && !userProfile?.username;

  return (
    <WizardModal
      modalHistory={modalHistory}
      modalType={MODALS.PREFERENCES}
      onClose={() => setCurrentModal(null)}
      titleIcon={<IconRuler3 />}
      title={"Unit settings"}
      withCloseButton={!isOnboarding}
      isShort
    >
      <UnitToggles outletContext={outletContext} />
      {isOnboarding ? (
        <Row>
          <Button
            variant="outline"
            mt="lg"
            ml="auto"
            onClick={() => setCurrentModal(MODALS.EDIT_PROFILE)}
            rightSection={<IconArrowRight size={14} />}
          >
            Next
          </Button>
        </Row>
      ) : null}
    </WizardModal>
  );
}
