import { useCallback, useEffect, useRef, useState } from "react";
import { Dropzone, type FileWithPath } from "@mantine/dropzone";
import { XMLParser } from "fast-xml-parser";
import {
  useFetcher,
  useLocation,
  useNavigate,
  useParams,
  useRouteLoaderData,
} from "@remix-run/react";
import { useForm, UseFormReturnType } from "@mantine/form";
import { Loader, Text, Title } from "@mantine/core";
import prettyBytes from "pretty-bytes";
import dayjs from "dayjs";
import {
  IconArrowRight,
  IconMailFast,
  IconScubaDiving,
  IconUpload,
} from "@tabler/icons-react";
import { Button } from "~/components/_common/Button/Button";
import { Container } from "~/components/_common/form/Container/Container";
import { Row } from "~/components/_common/Row/Row";
import { WizardModal } from "~/components/AddCreateWizard/WizardModal/WizardModal";
import { Form } from "~/components/_common/form/Form/Form";
import { MAX_LOG_FILE_SIZE } from "~/config/misc";
import { ModalTypes, MODALS } from "~/config/modals";
import { action as insertAction } from "~/routes/_api.insert-dive-logs";
import { action as sendFileAction } from "~/routes/_api.email-file-upload-error";
import { DiveSample, FILE_TYPES } from "~/config/dive-log";
import { garminParser } from "~/utils/logFileParsers/garminParser";
import { shearwaterParser } from "~/utils/logFileParsers/shearwaterParser";
import { suuntoFormatter } from "~/utils/logFileParsers/suuntoParser";
import {
  uddfParser,
  uddfFormatter,
  UDDF,
} from "~/utils/logFileParsers/uddfParser";
import { ModalData, PartialLoggedTrip, UserProfile } from "~/types/shared";
import { ORGANISATION, DIVE_SITE, PROFILE, TRIP } from "~/config/routes";
import { showNotication } from "~/config/notifications";
import { InlineButton } from "~/components/_common/InlineButton/InlineButton";
import { DiveSiteSearch } from "~/components/DiveSiteSearch/DiveSiteSearch";
import { TripSelect } from "~/components/_common/TripSelect/TripSelect";
import { DiveCompanySearch } from "~/components/DiveCompanySearch/DiveCompanySearch";
import {
  DIVE_LOG_MODAL_TABS_TYPES,
  OVERVIEW,
} from "~/components/modals/DiveLogModal/DiveLogModal";
import { Checkbox } from "~/components/_common/form/Checkbox/Checkbox";

import "@mantine/dropzone/styles.css";
import * as classes from "./UploadDiveLogModal.css";

function FormFields({
  form,
  setCurrentModal,
}: {
  form: UseFormReturnType<{
    dive_site_id: string;
    dive_site_name: string;
    trip_id: string;
    company_id: string;
    company_name: string;
  }>;
  setCurrentModal: (type: ModalTypes, data: ModalData) => void;
}) {
  const params = useParams();
  const rootData = useRouteLoaderData("root");
  const { logged_trips } = (
    rootData as unknown as {
      userProfile: { logged_trips: PartialLoggedTrip[] };
    }
  )?.userProfile ?? { logged_trips: [] };

  const { setFieldValue, setValues } = form;

  const { trip_id } = form.values;

  useEffect(() => {
    if (trip_id) {
      const trip = logged_trips.find((t) => t.id === trip_id);

      if (trip?.dive_companies) {
        const { company_name } = trip.dive_companies;

        setValues({
          company_name,
        });
      }
    }
  }, [trip_id, logged_trips, setValues]);

  useEffect(() => {
    if (params.tripId) {
      const trip = logged_trips.find((t) => t.id === params.tripId);
      if (trip) {
        setFieldValue("trip_id", trip.id);
      }
    }
  }, [logged_trips, params.tripId, setFieldValue]);

  const handleSetDiveSiteId = useCallback(
    (id: string) => {
      setFieldValue("dive_site_id", id);
    },
    [setFieldValue]
  );

  const setDiveCompanyFields = useCallback(
    (
      diveCompanyFields: {
        id: string;
        company_name: string;
      } | null
    ) => {
      const { id, company_name } = diveCompanyFields ?? {};
      setValues({
        company_id: id ?? "",
        company_name: company_name ?? "",
      });
    },
    [setValues]
  );

  return (
    <>
      <DiveSiteSearch
        diveSiteIdInputProps={{
          ...form.getInputProps("dive_site_id"),
          name: "dive_site_id",
        }}
        setDiveSiteId={handleSetDiveSiteId}
        diveSiteNameInputProps={{
          ...form.getInputProps("dive_site_name"),
          name: "dive_site_name",
        }}
        onChange={(v) => {
          form.setFieldValue("dive_site_name", v);
        }}
        onAddNewDiveSite={() => {
          setCurrentModal(MODALS.DIVE_SITE, {
            dive_site_name: form.values.dive_site_name,
          });
        }}
      />
      <TripSelect
        trips={logged_trips}
        selectProps={form.getInputProps("trip_id")}
        form={form}
      />
      <DiveCompanySearch
        hideLabel
        setDiveCompanyFields={setDiveCompanyFields}
        diveCompanyNameInputProps={{
          ...form.getInputProps("company_name"),
          name: "company_name",
        }}
        onChange={(v) => {
          form.setFieldValue("company_name", v);
        }}
        onAddNewCompany={() => {
          setCurrentModal(MODALS.CREATE_ORGANISATION, {
            company_name: form.values.company_name,
          });
        }}
      />
    </>
  );
}

function LogBookList({
  logs,
  selectedLogs,
  setSelectedLogs,
  setAllLogs,
  totalSelectedSelected,
  isUploading,
  shiftKeyPressed,
}: {
  logs: UDDF[];
  selectedLogs: boolean[];
  setSelectedLogs: (logs: boolean[]) => void;
  setAllLogs: (logs: unknown[], value: boolean) => void;
  totalSelectedSelected: number;
  isUploading: boolean;
  shiftKeyPressed: boolean;
}) {
  const noneSelected = selectedLogs.every((log) => !log);
  const allSelected = !noneSelected && selectedLogs.every(Boolean);
  const someSelected = selectedLogs.some(Boolean) && !allSelected;

  const latestSelectedIndex = useRef(-1);

  if (isUploading) {
    return (
      <div className={classes.loadingContainer}>
        <Loader type="bars" color="white" />
        <Text className={classes.infoText}>
          Uploading {totalSelectedSelected} dive logs...
        </Text>
      </div>
    );
  }

  return (
    <>
      <Text className={classes.infoText} mb="lg">
        Please select the logs you wish to upload:
      </Text>
      <Checkbox
        indeterminate={someSelected}
        checked={allSelected}
        ml="sm"
        mb="xs"
        label={`${totalSelectedSelected} of ${logs.length} selected`}
        onChange={() => {
          if (someSelected || allSelected) {
            setAllLogs(logs, false);
          } else {
            setAllLogs(logs, true);
          }
        }}
      />
      <ol className={classes.logList}>
        {logs.map((log, i) => {
          if (!log?.informationbeforedive) return null;
          const { datetime } = log.informationbeforedive;
          return (
            <li key={i}>
              <Checkbox
                checked={Boolean(selectedLogs[i])}
                onChange={() => {
                  if (shiftKeyPressed) {
                    const selectedValue = selectedLogs[i];

                    if (i > latestSelectedIndex.current) {
                      const next = [...selectedLogs];
                      for (let j = latestSelectedIndex.current; j <= i; j++) {
                        next[j] = !selectedValue;
                      }
                      setSelectedLogs(next);
                    } else {
                      const next = [...selectedLogs];
                      for (let j = i; j <= latestSelectedIndex.current; j++) {
                        next[j] = !selectedValue;
                      }
                      setSelectedLogs(next);
                    }
                  } else {
                    const next = [...selectedLogs];
                    next[i] = !next[i];
                    setSelectedLogs(next);
                  }

                  latestSelectedIndex.current = i;
                }}
                classNames={{
                  body: classes.checkboxBody,
                  inner: classes.checkboxInner,
                  label: classes.checkboxLabel,
                  labelWrapper: classes.checkboxLabelWrapper,
                }}
                label={
                  <>
                    <span className={classes.diveLogNumber}>
                      # {logs.length - i}:{" "}
                    </span>
                    <span className={classes.diveLogDate}>
                      {dayjs(datetime).format("DD MMM YYYY HH:mm")}
                    </span>
                  </>
                }
              />
            </li>
          );
        })}
      </ol>
    </>
  );
}

function FormStuff({
  userProfile,
  onClose,
  goBack,
  setCurrentModal,
  modalData,
  setDiveLogModalTab,
  shiftKeyPressed,
}: {
  userProfile: UserProfile;
  onClose: () => void;
  goBack: () => void;
  setCurrentModal: (type: ModalTypes, data: ModalData) => void;
  modalData?: ModalData;
  setDiveLogModalTab: (tab: DIVE_LOG_MODAL_TABS_TYPES) => void;
  shiftKeyPressed: boolean;
}) {
  const [submitCount, setSubmitCount] = useState(
    new Date().getTime() /* A bit of a hack to ensure the cache is cleared on mount */
  );
  const upsertFetcher = useFetcher<typeof insertAction>({
    key: `upload-${submitCount}`,
  });
  const sendFileFetcher = useFetcher<typeof sendFileAction>({
    key: `send-${submitCount}`,
  });
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { companyId, siteId, tripId } = useParams();
  const formRef = useRef<HTMLFormElement | null>(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [erroredFiles, setErroredFiles] = useState<FileWithPath[]>([]);
  const [succesFiles, setSuccessFiles] = useState<FileWithPath[]>([]);
  const [diveLogs, setDiveLogs] = useState<UDDF[] | null>(null);
  const [selectedLogs, setSelectedLogs] = useState<boolean[]>([]);
  const [erroredFilesSent, setErroredFilesSent] = useState(false);
  const [currentPage, setCurrentPage] = useState<"form" | "upload" | "results">(
    "form"
  );
  const onSuccessCalled = useRef(false);

  const totalSelectedSelected = selectedLogs.filter(Boolean).length;

  const form = useForm({
    initialValues: {
      dive_site_id: "",
      dive_site_name: "",
      trip_id: "",
      company_id: "",
      company_name: "",
    },
  });

  const { submit, data } = upsertFetcher;
  const isUploading = upsertFetcher.state !== "idle";
  const apiError = data?.error || sendFileFetcher.data?.error;

  const setAllLogs = (data: unknown[], value: boolean) =>
    setSelectedLogs(new Array(data.length).fill(value));

  const reset = () => {
    setErroredFiles([]);
    setErroredFilesSent(false);
    setSubmitCount((prev) => prev + 1);
    onSuccessCalled.current = false;
  };

  const uploadDiveLogs = (
    diveLogFiles?: {
      dive_samples: DiveSample[];
    }[]
  ) => {
    const formData = new FormData();

    const formValues = {
      dive_site_id: form.values.dive_site_id || null,
      trip_id: form.values.trip_id || null,
      company_id: form.values.company_id || null,
      company_name: form.values.company_name || null,
    };

    const diveLogs = diveLogFiles?.map((diveLogFile) => ({
      ...diveLogFile,
      ...formValues,
    }));

    formData.append(
      "dive_logs",
      JSON.stringify(diveLogs ?? [{ ...formValues, dive_samples: [] }])
    );

    submit(formData, {
      method: "post",
      action: "/insert-dive-logs",
    });
  };

  const handleOnDrop = async (files: FileWithPath[]) => {
    setIsProcessing(true);

    const processedFiles: {
      dive_samples: DiveSample[];
    }[] = [];
    const tempErroredFiles: FileWithPath[] = [];
    const tempSuccessFiles: FileWithPath[] = [];

    for await (const file of files) {
      try {
        const type = file.type || file.name.split(".").pop();

        if (type === FILE_TYPES.XML || type === FILE_TYPES.TEXT_XML) {
          const xml = await file.text();

          const parser = new XMLParser();
          const sourceData = parser.parse(xml);

          if (sourceData?.dive) {
            const newData = shearwaterParser(sourceData);
            processedFiles.push(newData);
            tempSuccessFiles.push(file);
          } else if (sourceData?.Dive) {
            const newData = suuntoFormatter(sourceData);
            processedFiles.push(newData);
          } else {
            tempErroredFiles.push(file);
          }
        } else if (type === FILE_TYPES.FIT) {
          const newData = await garminParser({ file });
          processedFiles.push(newData);
          tempSuccessFiles.push(file);
        } else if (type === FILE_TYPES.UDDF) {
          const data = await uddfParser({ file });

          if (Array.isArray(data)) {
            if (data.length) {
              const sortedDiveLogs = data.sort(
                (a, b) =>
                  b.informationbeforedive.divenumber -
                  a.informationbeforedive.divenumber
              );

              setDiveLogs(sortedDiveLogs);
              setAllLogs(sortedDiveLogs, true);
            } else {
              tempErroredFiles.push(file);
            }
          } else {
            const newData = uddfFormatter(data);
            processedFiles.push(newData);
            tempSuccessFiles.push(file);
          }
        } else {
          tempErroredFiles.push(file);
        }
      } catch (error) {
        if (typeof error === "string") {
          console.error(error);
        } else if (error instanceof Error) {
          console.error(error.message);
        }
        tempErroredFiles.push(file);
      }
    }

    uploadDiveLogs(processedFiles);
    setErroredFiles(tempErroredFiles);
    setSuccessFiles(tempSuccessFiles);
    setIsProcessing(false);
  };

  const navigateToDiveLog = useCallback(
    (diveLogIds: (string | null)[]) => {
      setDiveLogModalTab(OVERVIEW);

      if (diveLogIds.length === 1 && diveLogIds[0]) {
        const [diveLogId] = diveLogIds;
        if (pathname.startsWith(DIVE_SITE)) {
          navigate(`${DIVE_SITE}/${siteId}/dive-log/${diveLogId}`);
        } else if (pathname.startsWith(TRIP)) {
          navigate(`${TRIP}/${tripId}/dive-site/_/${diveLogId}`);
        } else if (pathname.startsWith(ORGANISATION)) {
          navigate(`${ORGANISATION}/${companyId}/dive-site/_/${diveLogId}`);
        } else {
          navigate(`${PROFILE}/${userProfile.id}${DIVE_SITE}/_/${diveLogId}`);
        }
      } else {
        if (form.values.trip_id) {
          navigate(`${TRIP}/${form.values.trip_id}`);
        } else if (
          form.values.dive_site_id &&
          form.values.dive_site_id === siteId
        ) {
          navigate(`${DIVE_SITE}/${siteId}`);
        } else {
          navigate(`${PROFILE}/${userProfile.id}`);
        }
      }

      onClose();
    },
    [
      companyId,
      navigate,
      onClose,
      pathname,
      setDiveLogModalTab,
      siteId,
      tripId,
      userProfile.id,
      form.values,
    ]
  );

  const { setValues } = form;

  useEffect(() => {
    if (modalData) {
      setValues(modalData);
    }
  }, [modalData, setValues]);

  useEffect(
    function onSucces() {
      const { diveLogIds } = data ?? {};
      if (diveLogIds?.length && !onSuccessCalled.current && !isUploading) {
        onSuccessCalled.current = true;

        if (!succesFiles.length) {
          navigateToDiveLog(diveLogIds);
        } else {
          setCurrentPage("results");
        }
      }
    },
    [
      data,
      navigate,
      onClose,
      pathname,
      userProfile.id,
      siteId,
      tripId,
      companyId,
      setDiveLogModalTab,
      succesFiles.length,
      navigateToDiveLog,
      isUploading,
    ]
  );

  useEffect(
    function onApiError() {
      if (apiError) {
        showNotication(apiError, "error");
      }
    },
    [apiError]
  );

  useEffect(
    function onProcessingError() {
      if (erroredFiles?.length) {
        setCurrentPage("results");
        showNotication(
          "There was an error processing some of your log files. Please send them to us for investigation.",
          "error"
        );
      }
    },
    [erroredFiles]
  );

  useEffect(
    function onSendFileSuccess() {
      if (erroredFiles?.length && sendFileFetcher.data?.success) {
        showNotication(
          `Thanks ${userProfile.name}! File successfully sent for investigation. We will let you know when we have an update.`,
          "success"
        );

        setErroredFilesSent(true);
      }
    },
    [erroredFiles, userProfile.name, sendFileFetcher.data]
  );

  const SendFileButton = () => {
    return (
      <Button
        variant="default"
        disabled={sendFileFetcher.state !== "idle" || erroredFilesSent}
        loading={sendFileFetcher.state !== "idle"}
        className={classes.resultsCtas}
        rightSection={<IconMailFast />}
        onClick={() => {
          if (erroredFiles?.length) {
            const formData = new FormData();
            erroredFiles.forEach((file, i) => {
              formData.append(`file__${i}`, file);
            });
            formData.append("type", "error");
            formData.append("username", userProfile.username);
            sendFileFetcher.submit(formData, {
              method: "post",
              encType: "multipart/form-data",
              action: "/email-file-upload-error",
            });
          }
        }}
      >
        Send files
      </Button>
    );
  };

  return (
    <Container
      footer={
        <Row flexEnd>
          {diveLogs?.length ? (
            <>
              <Button
                variant="transparent"
                mr="auto"
                disabled={upsertFetcher.state === "submitting"}
                onClick={() => {
                  setDiveLogs(null);
                }}
              >
                Cancel
              </Button>
              <Button
                variant="default"
                disabled={
                  upsertFetcher.state === "submitting" || !totalSelectedSelected
                }
                onClick={() => {
                  const filteredLogs = diveLogs?.filter(
                    (_, i) => selectedLogs[i]
                  );
                  const formattedLogs = filteredLogs?.map((log) =>
                    uddfFormatter(log)
                  );

                  uploadDiveLogs(formattedLogs);
                }}
              >
                Upload
              </Button>
            </>
          ) : (
            <>
              <Button
                variant="transparent"
                mr="auto"
                disabled={upsertFetcher.state === "submitting"}
                onClick={() => {
                  if (currentPage === "form") {
                    goBack();
                  } else if (currentPage === "upload") {
                    setCurrentPage("form");
                  } else {
                    setCurrentPage("upload");
                  }

                  reset();
                }}
              >
                Go back
              </Button>
              {currentPage === "form" ? (
                <Button
                  variant="default"
                  onClick={() => {
                    setCurrentPage("upload");
                  }}
                >
                  {form.isDirty() ? "Next" : "Skip"}
                </Button>
              ) : currentPage === "results" ? (
                <Button
                  variant="light"
                  disabled={upsertFetcher.state === "submitting"}
                  onClick={() => {
                    setCurrentPage("form");
                    reset();
                  }}
                >
                  Start over
                </Button>
              ) : null}
            </>
          )}
        </Row>
      }
    >
      {currentPage === "form" ? (
        <Form ref={formRef}>
          <Text className={classes.infoText}>
            Where relevant, please select a dive site, trip and dive company
            associated with your log(s). If you are unsure or are uploading
            multiple logs with different values, you can skip this step and add
            them later.
          </Text>
          <FormFields form={form} setCurrentModal={setCurrentModal} />
        </Form>
      ) : diveLogs?.length ? (
        <LogBookList
          logs={diveLogs}
          selectedLogs={selectedLogs}
          setSelectedLogs={setSelectedLogs}
          setAllLogs={setAllLogs}
          totalSelectedSelected={totalSelectedSelected}
          isUploading={isUploading}
          shiftKeyPressed={shiftKeyPressed}
        />
      ) : currentPage === "upload" ? (
        <>
          <Text className={classes.infoText}>
            Upload a single dive log, multiple dive logs or your entire logbook.
          </Text>
          <InlineButton onClick={() => uploadDiveLogs()}>
            Enter manually
          </InlineButton>
          <Dropzone
            onDrop={handleOnDrop}
            onReject={([{ errors }]) => {
              if (errors[0].code === "file-too-large") {
                showNotication(
                  `File is too large. Max file size is ${prettyBytes(
                    MAX_LOG_FILE_SIZE
                  )}`,
                  "error"
                );
              }
            }}
            maxSize={MAX_LOG_FILE_SIZE}
            classNames={{
              root: classes.dropzone,
              inner: classes.dropzoneInner,
            }}
            disabled={isProcessing || isUploading}
          >
            {isProcessing || isUploading ? (
              <Loader type="bars" color="white" />
            ) : (
              <IconUpload />
            )}
            {isProcessing ? (
              <Text className={classes.infoText}>Processing...</Text>
            ) : isUploading ? (
              <Text className={classes.infoText}>Uploading...</Text>
            ) : (
              <>
                <Text
                  className={classes.infoText}
                  style={{
                    opacity: 1,
                  }}
                >
                  Click to select, or drag files here...
                </Text>
                <Text className={classes.infoText}>
                  We currently support UDDF, Suunto and Shearwater XML and
                  Garmin FIT files.
                </Text>
              </>
            )}
          </Dropzone>
        </>
      ) : (
        <div className={classes.uploadResults}>
          {data?.diveLogIds?.length ? (
            <div className={classes.resultsColumn}>
              <Title order={3} className={classes.resultsHeading}>
                Successfull uploads:
              </Title>
              <div className={classes.resultsColumnScroll}>
                <ul className={classes.uploadedFilesList}>
                  {succesFiles.map((file, i) => (
                    <li className={classes.fileListItem} key={i}>
                      {file.path}
                    </li>
                  ))}
                </ul>
              </div>
              <Button
                variant="outline"
                className={classes.resultsCtas}
                onClick={() => {
                  navigateToDiveLog(data?.diveLogIds);
                }}
                rightSection={<IconArrowRight />}
              >
                View logs
              </Button>
            </div>
          ) : null}
          {erroredFiles.length ? (
            <div className={classes.resultsColumn}>
              <Title order={3} className={classes.resultsHeading}>
                Unsuccessful uploads:
              </Title>
              <div className={classes.resultsColumnScroll}>
                <ul className={classes.uploadedFilesList}>
                  {erroredFiles.map((file, i) => (
                    <li className={classes.fileListItem} key={i}>
                      {file.path}
                    </li>
                  ))}
                </ul>
              </div>
              <SendFileButton />
            </div>
          ) : null}
        </div>
      )}
    </Container>
  );
}

export function UploadDiveLogModal({
  modalHistory,
  modalType,
  onClose,
  goBack,
  userProfile,
  setCurrentModal,
  modalData,
  setDiveLogModalTab,
  shiftKeyPressed,
}: {
  modalHistory: ModalTypes[];
  modalType: typeof MODALS.DIVE_LOG;
  onClose: () => void;
  goBack: () => void;
  userProfile: UserProfile;
  setCurrentModal: (type: ModalTypes, data: ModalData) => void;
  modalData?: ModalData;
  setDiveLogModalTab: (tab: DIVE_LOG_MODAL_TABS_TYPES) => void;
  shiftKeyPressed: boolean;
}) {
  return (
    <WizardModal
      modalHistory={modalHistory}
      modalType={modalType}
      onClose={onClose}
      titleIcon={<IconScubaDiving />}
      title={"Log dives"}
    >
      <FormStuff
        onClose={onClose}
        goBack={goBack}
        userProfile={userProfile}
        setCurrentModal={setCurrentModal}
        modalData={modalData}
        setDiveLogModalTab={setDiveLogModalTab}
        shiftKeyPressed={shiftKeyPressed}
      />
    </WizardModal>
  );
}
