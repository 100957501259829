import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  type FormEvent,
} from "react";
import {
  useFetcher,
  useNavigate,
  useParams,
  useRevalidator,
} from "@remix-run/react";
import {
  IconBrandSafari,
  IconCheck,
  IconEyeOff,
  IconEye,
} from "@tabler/icons-react";
import { type UseFormReturnType, useForm } from "@mantine/form";
import { Title } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { Tooltip } from "~/components/_common/Tooltip/Tooltip";
import { type action } from "~/routes/_api.trip";
import { WizardModal } from "~/components/AddCreateWizard/WizardModal/WizardModal";
import { Form } from "~/components/_common/form/Form/Form";
import { NativeSelect } from "~/components/_common/form/NativeSelect/NativeSelect";
import { Button } from "~/components/_common/Button/Button";
import { DateInput } from "~/components/_common/form/DateInput/DateInput";
import {
  type TripErrors,
  type TripFormInputs,
  TRIP_TYPES,
  tripFormValidator,
} from "~/config/trip";
import { Row } from "~/components/_common/Row/Row";
import { ErrorText } from "~/components/_common/form/ErrorText/ErrorText";
import { vars } from "~/config/theme";
import { TRIP } from "~/config/routes";
import { LocationSearchClient } from "~/components/LocationSearch/LocationSearch";
import { MODALS, type ModalTypes } from "~/config/modals";
import type { LeaveWarnings, LoggedTrip, ModalData } from "~/types/shared";
import { Container } from "~/components/_common/form/Container/Container";
import { Switch } from "~/components/_common/form/Switch/Switch";
import { CountrySearch } from "~/components/CountrySearch/CountrySearch";
import { DiveCompanySearch } from "~/components/DiveCompanySearch/DiveCompanySearch";
import { COMPANY_TYPES } from "~/config/organisation";
import { getDateOnly } from "~/utils/getDateOnly";

function CountryLocationFields({
  form,
  errors,
  handleSetCountryFields,
  handleResetCountryFields,
}: {
  form: UseFormReturnType<Partial<TripFormInputs>>;
  errors: TripErrors;
  handleSetCountryFields: (countryId: string, countryCode: string) => void;
  handleResetCountryFields: () => void;
}) {
  return (
    <>
      <CountrySearch
        value={form.values.countries?.country}
        onChange={(v) =>
          form.setValues({
            countries: {
              country: v,
            },
          })
        }
        onSetCountryFields={handleSetCountryFields}
        onResetCountryFields={handleResetCountryFields}
        countryIdProps={{
          ...form.getInputProps("country_id"),
          name: "country_id",
        }}
        error={errors.country_id}
      />

      <LocationSearchClient
        required
        disabled={!form.values.country_code}
        filter={form.values.country_code}
        locationInputProps={{
          ...form.getInputProps("location"),
          name: "location",
          label: "Location",
          placeholder: "Search locations...",
        }}
        placeIdInputProps={{
          ...form.getInputProps("place_id"),
          name: "place_id",
        }}
        latitudeInputProps={{
          ...form.getInputProps("latitude"),
          name: "latitude",
        }}
        longitudeInputProps={{
          ...form.getInputProps("longitude"),
          name: "longitude",
        }}
        error={errors.location || errors.place_id}
      />
    </>
  );
}

function LiveaboardFields({
  form,
  errors,
  setCurrentModal,
  setDiveCompanyFields,
  handleSetCountryFields,
  handleResetCountryFields,
}: {
  form: UseFormReturnType<Partial<TripFormInputs>>;
  errors: TripErrors;
  setCurrentModal: (type: ModalTypes, data: ModalData) => void;
  setDiveCompanyFields: (
    diveCompanyFields: {
      id: string;
      company_name: string;
    } | null
  ) => void;
  handleSetCountryFields: (countryId: string, countryCode: string) => void;
  handleResetCountryFields: () => void;
}) {
  return (
    <>
      <div>
        <DiveCompanySearch
          companyType={COMPANY_TYPES.LIVEABOARD}
          setDiveCompanyFields={setDiveCompanyFields}
          diveCompanyNameInputProps={{
            ...form.getInputProps("company_name"),
            name: "company_name",
            required: true,
          }}
          onChange={(v) => {
            form.setFieldValue("company_name", v);
          }}
          onAddNewCompany={() => {
            setCurrentModal(MODALS.CREATE_ORGANISATION, {
              company_name: form.values.company_name,
            });
          }}
        />
      </div>
      <CountryLocationFields
        form={form}
        errors={errors}
        handleSetCountryFields={handleSetCountryFields}
        handleResetCountryFields={handleResetCountryFields}
      />
      <Row alignTop>
        <DateInput
          required
          name="start_date"
          label="Start date"
          placeholder="Select the start date"
          {...form.getInputProps("start_date")}
          error={errors.start_date}
          style={{
            flex: 1,
          }}
        />
        <DateInput
          required
          name="end_date"
          label="End date"
          placeholder="Select the end date"
          {...form.getInputProps("end_date")}
          error={errors.end_date}
          style={{
            flex: 1,
          }}
        />
      </Row>
    </>
  );
}

function ResortFields({
  form,
  errors,
  setCurrentModal,
  setDiveCompanyFields,
  handleSetCountryFields,
  handleResetCountryFields,
}: {
  form: UseFormReturnType<Partial<TripFormInputs>>;
  errors: TripErrors;
  setCurrentModal: (type: ModalTypes, data: ModalData) => void;
  setDiveCompanyFields: (
    diveCompanyFields: {
      id: string;
      company_name: string;
    } | null
  ) => void;
  handleSetCountryFields: (countryId: string, countryCode: string) => void;
  handleResetCountryFields: () => void;
}) {
  return (
    <>
      <div>
        <DiveCompanySearch
          companyType={COMPANY_TYPES.RESORT}
          setDiveCompanyFields={setDiveCompanyFields}
          diveCompanyNameInputProps={{
            ...form.getInputProps("company_name"),
            name: "company_name",
            defaultValue: form.values.company_name || "",
            required: true,
          }}
          onChange={(v) => {
            form.setFieldValue("company_name", v);
          }}
          onAddNewCompany={() => {
            setCurrentModal(MODALS.CREATE_ORGANISATION, {
              company_name: form.values.company_name,
            });
          }}
        />
      </div>

      <CountryLocationFields
        form={form}
        errors={errors}
        handleSetCountryFields={handleSetCountryFields}
        handleResetCountryFields={handleResetCountryFields}
      />

      <Row alignTop>
        <DateInput
          required
          name="start_date"
          label="Start date"
          placeholder="Enter the start date"
          {...form.getInputProps("start_date")}
          error={errors.start_date}
          style={{
            flex: 1,
          }}
        />
        <DateInput
          required
          name="end_date"
          label="End date"
          placeholder="Enter the end date"
          {...form.getInputProps("end_date")}
          error={errors.end_date}
          style={{
            flex: 1,
          }}
        />
      </Row>
    </>
  );
}

function DayTripFields({
  form,
  errors,
  setCurrentModal,
  setDiveCompanyFields,
  handleSetCountryFields,
  handleResetCountryFields,
}: {
  form: UseFormReturnType<Partial<TripFormInputs>>;
  errors: TripErrors;
  setCurrentModal: (type: ModalTypes, data: ModalData) => void;
  setDiveCompanyFields: (
    diveCompanyFields: {
      id: string;
      company_name: string;
    } | null
  ) => void;
  handleSetCountryFields: (countryId: string, countryCode: string) => void;
  handleResetCountryFields: () => void;
}) {
  return (
    <>
      <div>
        <DiveCompanySearch
          setDiveCompanyFields={setDiveCompanyFields}
          diveCompanyNameInputProps={{
            ...form.getInputProps("company_name"),
            name: "company_name",
            defaultValue: form.values.company_name || "",
          }}
          onChange={(v) => {
            form.setFieldValue("company_name", v);
          }}
          onAddNewCompany={() => {
            setCurrentModal(MODALS.CREATE_ORGANISATION, {
              company_name: form.values.company_name,
            });
          }}
        />
      </div>

      <CountryLocationFields
        form={form}
        errors={errors}
        handleSetCountryFields={handleSetCountryFields}
        handleResetCountryFields={handleResetCountryFields}
      />

      <DateInput
        required
        name="start_date"
        label="Date"
        placeholder="Enter the date"
        {...form.getInputProps("start_date")}
        error={errors.start_date}
      />
    </>
  );
}

function OtherFields({
  form,
  errors,
  setCurrentModal,
  setDiveCompanyFields,
  handleSetCountryFields,
  handleResetCountryFields,
}: {
  form: UseFormReturnType<Partial<TripFormInputs>>;
  errors: TripErrors;
  setCurrentModal: (type: ModalTypes, data: ModalData) => void;
  setDiveCompanyFields: (
    diveCompanyFields: {
      id: string;
      company_name: string;
    } | null
  ) => void;
  handleSetCountryFields: (countryId: string, countryCode: string) => void;
  handleResetCountryFields: () => void;
}) {
  return (
    <>
      <div>
        <DiveCompanySearch
          setDiveCompanyFields={setDiveCompanyFields}
          diveCompanyNameInputProps={{
            ...form.getInputProps("company_name"),
            name: "company_name",
            defaultValue: form.values.company_name || "",
          }}
          onChange={(v) => {
            form.setFieldValue("company_name", v);
          }}
          onAddNewCompany={() => {
            setCurrentModal(MODALS.CREATE_ORGANISATION, {
              company_name: form.values.company_name,
            });
          }}
        />
      </div>

      <CountryLocationFields
        form={form}
        errors={errors}
        handleSetCountryFields={handleSetCountryFields}
        handleResetCountryFields={handleResetCountryFields}
      />

      <Row alignTop>
        <DateInput
          required
          name="start_date"
          label="Start date"
          placeholder="Enter the start date"
          {...form.getInputProps("start_date")}
          error={errors.start_date}
          style={{
            flex: 1,
          }}
        />
        <DateInput
          required
          name="end_date"
          label="End date"
          placeholder="Enter the end date"
          {...form.getInputProps("end_date")}
          error={errors.end_date}
          style={{
            flex: 1,
          }}
        />
      </Row>
    </>
  );
}

function FormStuff({
  onClose,
  setCurrentModal,
  currentModal,
  modalHistory,
  goBack,
  handleCheckGoBack,
  initialValues,
  setIsFormDirty,
  setShowLeaveWarning,
  showLeaveWarning,
  setNewTripId,
  setTempCountry,
  modalData,
}: {
  onClose: () => void;
  setCurrentModal: (modal: ModalTypes | null) => void;
  currentModal: ModalTypes | null;
  modalHistory: ModalTypes[];
  goBack: () => void;
  handleCheckGoBack: () => void;
  initialValues?: LoggedTrip;
  setIsFormDirty: (value: boolean) => void;
  setShowLeaveWarning: (value: LeaveWarnings) => void;
  showLeaveWarning: LeaveWarnings;
  setNewTripId: (id: string) => void;
  setTempCountry: (
    country: {
      country: string;
      country_id: string;
      country_code: string;
    } | null
  ) => void;
  modalData?: ModalData;
}) {
  const { countries } = initialValues ?? {};

  const navigate = useNavigate();
  const { revalidate } = useRevalidator();
  const { tripId } = useParams();
  const fetcher = useFetcher<typeof action>();
  const formRef = useRef<HTMLFormElement | null>(null);
  const form = useForm<Partial<TripFormInputs>>({
    initialValues: {
      ...initialValues,
      countries: {
        country: countries?.country ?? "",
      },
      country_id: countries?.id ?? "",
      country_code: countries?.alpha_2 ?? "",
      location: initialValues?.location ?? "",
      place_id: initialValues?.place_id ?? "",
      latitude: initialValues?.latitude ?? "",
      longitude: initialValues?.longitude ?? "",
      company_id: initialValues?.dive_companies?.id ?? "",
      company_name: initialValues?.dive_companies?.company_name ?? "",
      is_public: initialValues?.is_public ?? false,
    },
    validate: tripFormValidator,
  });

  const { errors } = fetcher.data ?? { errors: {} };
  const allErrors: TripErrors = useMemo(() => {
    return fetcher.state === "submitting" ? {} : { ...errors, ...form.errors };
  }, [fetcher.state, errors, form.errors]);
  const isFormDirty = form.isDirty();
  const hasErrors = Object.keys(allErrors).length > 0;

  const { reset, setFieldValue, setValues } = form;

  const resetForm = useCallback(() => {
    reset();
    formRef.current?.reset();
  }, [reset]);

  const handleSubmit = (e?: FormEvent) => {
    e?.preventDefault();
    form.clearErrors();

    const { hasErrors: localErrors } = form.validate();

    if (!localErrors && formRef?.current) {
      const formData = new FormData(formRef.current);

      if (currentModal === MODALS.EDIT_TRIP && tripId) {
        formData.append("trip_id", tripId);
      }

      formData.append("company_id", form.values.company_id ?? "");
      formData.append("company_name", form.values.company_name ?? "");

      if (form.values.start_date) {
        formData.set("start_date", getDateOnly(form.values.start_date) ?? "");
      }

      if (form.values.end_date) {
        formData.set("end_date", getDateOnly(form.values.end_date) ?? "");
      }

      fetcher.submit(formData, {
        method: "post",
        action: "/trip",
      });
    }
  };

  useEffect(() => {
    setIsFormDirty(isFormDirty);
  }, [setIsFormDirty, isFormDirty]);

  useEffect(() => {
    if (modalData) {
      setValues(modalData);
    }
  }, [modalData, setValues]);

  useEffect(() => {
    if (!currentModal) {
      resetForm();
    }
  }, [resetForm, currentModal]);

  const country = form.values.countries?.country;
  const countryId = form.values.country_id;
  const countryCode = form.values.country_code;
  const tripType = form.values.trip_type;

  useEffect(() => {
    if (country && countryId && countryCode) {
      setTempCountry({
        country,
        country_id: countryId,
        country_code: countryCode,
      });
    } else {
      setTempCountry(null);
    }
  }, [country, countryId, setTempCountry, countryCode]);

  useEffect(
    function onSuccess() {
      const prevModal = modalHistory[modalHistory.length - 2];

      if (
        fetcher.state === "idle" &&
        fetcher.data?.tripId &&
        !hasErrors &&
        isFormDirty &&
        currentModal
      ) {
        notifications.clean();
        notifications.show({
          message:
            currentModal === MODALS.EDIT_TRIP
              ? "Trip successfully updated"
              : "Trip successfully created",
          color: vars.colors.green[6],
          icon: <IconCheck />,
        });

        if (
          prevModal === MODALS.DIVE_LOG_EDIT ||
          prevModal === MODALS.DIVE_LOG ||
          prevModal === MODALS.UPLOAD_DIVE_PHOTOS
        ) {
          goBack();
          setNewTripId(fetcher.data.tripId);
        } else if (currentModal !== MODALS.EDIT_TRIP) {
          navigate(`${TRIP}/${fetcher.data.tripId}`);
          revalidate();
          onClose();
        } else {
          onClose();
        }

        resetForm();
      }
    },
    [
      fetcher.state,
      fetcher.data,
      hasErrors,
      onClose,
      isFormDirty,
      currentModal,
      navigate,
      revalidate,
      modalHistory,
      goBack,
      setNewTripId,
      resetForm,
    ]
  );

  const setDiveCompanyFields = useCallback(
    (
      diveCompanyFields: {
        id: string;
        company_name: string;
      } | null
    ) => {
      const { id, company_name } = diveCompanyFields ?? {};

      setValues({
        company_id: id,
        company_name: company_name ?? "",
      });
    },
    [setValues]
  );

  useEffect(() => {
    if (tripType !== initialValues?.trip_type) {
      setDiveCompanyFields(null);
    }
  }, [setDiveCompanyFields, tripType, initialValues?.trip_type]);

  useEffect(() => {
    if (hasErrors) {
      console.error(allErrors);
    }
  }, [hasErrors, allErrors]);

  const handleSetCountryFields = useCallback(
    (countryId: string, countryCode: string) => {
      setFieldValue("country_code", countryCode);
      setFieldValue("country_id", countryId);
    },
    [setFieldValue]
  );

  const handleResetCountryFields = useCallback(() => {
    setFieldValue("country_code", "");
    setFieldValue("country_id", "");
  }, [setFieldValue]);

  return !showLeaveWarning ? (
    <Form onSubmit={handleSubmit} ref={formRef}>
      <Container
        footer={
          <>
            <Row>
              {currentModal !== MODALS.EDIT_TRIP ? (
                <Button
                  variant="transparent"
                  mr="auto"
                  disabled={fetcher.state === "submitting"}
                  onClick={() => {
                    handleCheckGoBack();
                  }}
                >
                  Go back
                </Button>
              ) : null}

              {tripType ? (
                <Tooltip
                  label={
                    form.values.is_public
                      ? "Toggle to make this trip only visible to yourself and other trip members."
                      : "Toggle to make this trip public and visible to everyone."
                  }
                  refProp="rootRef"
                  openDelay={500}
                >
                  <Switch
                    size="lg"
                    onLabel={<IconEye />}
                    offLabel={<IconEyeOff />}
                    name="is_public"
                    {...form.getInputProps("is_public")}
                    defaultChecked={Boolean(form.values.is_public)}
                  />
                </Tooltip>
              ) : null}
              <Button
                variant="default"
                loading={fetcher.state === "submitting"}
                disabled={fetcher.state === "submitting" || !isFormDirty}
                onClick={() => {
                  handleSubmit();
                }}
              >
                Save
              </Button>
            </Row>
            <ErrorText>{allErrors.misc}</ErrorText>
          </>
        }
      >
        <NativeSelect
          required
          name="trip_type"
          label="Trip type"
          {...form.getInputProps("trip_type")}
          data={[
            { label: "Select trip type", value: "" },
            { label: "Liveaboard", value: TRIP_TYPES.LIVEABOARD },
            { label: "Resort", value: TRIP_TYPES.RESORT },
            { label: "Day trip", value: TRIP_TYPES.DAY_TRIP },
            { label: "Custom / Other", value: TRIP_TYPES.OTHER },
          ]}
          error={allErrors.trip_type}
        />

        {tripType === TRIP_TYPES.LIVEABOARD && (
          <LiveaboardFields
            form={form}
            errors={allErrors}
            setCurrentModal={setCurrentModal}
            setDiveCompanyFields={setDiveCompanyFields}
            handleSetCountryFields={handleSetCountryFields}
            handleResetCountryFields={handleResetCountryFields}
          />
        )}

        {tripType === TRIP_TYPES.RESORT && (
          <ResortFields
            form={form}
            errors={allErrors}
            setCurrentModal={setCurrentModal}
            setDiveCompanyFields={setDiveCompanyFields}
            handleSetCountryFields={handleSetCountryFields}
            handleResetCountryFields={handleResetCountryFields}
          />
        )}

        {tripType === TRIP_TYPES.DAY_TRIP && (
          <DayTripFields
            form={form}
            errors={allErrors}
            setCurrentModal={setCurrentModal}
            setDiveCompanyFields={setDiveCompanyFields}
            handleSetCountryFields={handleSetCountryFields}
            handleResetCountryFields={handleResetCountryFields}
          />
        )}

        {tripType === TRIP_TYPES.OTHER && (
          <OtherFields
            form={form}
            errors={allErrors}
            setCurrentModal={setCurrentModal}
            setDiveCompanyFields={setDiveCompanyFields}
            handleSetCountryFields={handleSetCountryFields}
            handleResetCountryFields={handleResetCountryFields}
          />
        )}
      </Container>
    </Form>
  ) : (
    <Container>
      <Title
        order={2}
        size="h3"
        style={{
          textAlign: "center",
          marginBottom: 20,
        }}
      >
        {showLeaveWarning === "close"
          ? "You have unsaved changes. Are you sure you want to leave?"
          : "You have unsaved changes. Are you sure you want to go back?"}
      </Title>
      <Row>
        <Button
          variant="light"
          style={{ flex: 1 }}
          onClick={() => {
            setShowLeaveWarning(null);
          }}
          disabled={fetcher.state === "submitting"}
        >
          No thanks
        </Button>
        <Button
          variant="default"
          style={{ flex: 1 }}
          onClick={() => {
            if (showLeaveWarning === "go-back") {
              goBack();
            } else {
              onClose();
            }
          }}
          loading={fetcher.state === "submitting"}
          disabled={fetcher.state === "submitting"}
        >
          Yes please
        </Button>
      </Row>
    </Container>
  );
}

export function TripModal({
  modalHistory,
  modalType,
  setCurrentModal,
  currentModal,
  goBack,
  initialValues,
  setNewTripId,
  setTempCountry,
  modalData,
}: {
  modalHistory: ModalTypes[];
  modalType: ModalTypes;
  setCurrentModal: (modal: ModalTypes | null) => void;
  currentModal: ModalTypes | null;
  goBack: () => void;
  initialValues?: LoggedTrip;
  setNewTripId: (id: string) => void;
  setTempCountry: (
    country: {
      country: string;
      country_id: string;
      country_code: string;
    } | null
  ) => void;
  modalData?: ModalData;
}) {
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [showLeaveWarning, setShowLeaveWarning] = useState<LeaveWarnings>(null);

  const handleClose = () => {
    setCurrentModal(null);

    setTimeout(() => {
      setShowLeaveWarning(null);
    }, 200);
  };

  const handleCheckClose = () => {
    if (!isFormDirty) {
      handleClose();
    } else {
      setShowLeaveWarning("close");
    }
  };

  const handleGoBack = () => {
    goBack();

    setTimeout(() => {
      setShowLeaveWarning(null);
    }, 200);
  };

  const handleCheckGoBack = () => {
    if (!isFormDirty) {
      handleGoBack();
    } else {
      setShowLeaveWarning("go-back");
    }
  };

  return (
    <WizardModal
      modalHistory={modalHistory}
      modalType={modalType}
      onClose={handleCheckClose}
      withCloseButton={!showLeaveWarning}
      titleIcon={<IconBrandSafari />}
      title={currentModal === MODALS.EDIT_TRIP ? "Edit trip" : "Create a trip"}
      isShort={Boolean(showLeaveWarning)}
    >
      <FormStuff
        onClose={handleClose}
        goBack={handleGoBack}
        setCurrentModal={setCurrentModal}
        currentModal={currentModal}
        modalHistory={modalHistory}
        initialValues={initialValues}
        setIsFormDirty={setIsFormDirty}
        showLeaveWarning={showLeaveWarning}
        setShowLeaveWarning={setShowLeaveWarning}
        handleCheckGoBack={handleCheckGoBack}
        setNewTripId={setNewTripId}
        setTempCountry={setTempCountry}
        modalData={modalData}
      />
    </WizardModal>
  );
}
