export type DiveSiteFormInputs = {
  country: string | null;
  country_code: string | null;
  country_id: string | null;
  location: string | null;
  place_id: string | null;
  dive_site_name: string | null;
  latitude: string | number | null;
  longitude: string | number | null;
};

export type DiveSiteErrors = {
  [K in keyof DiveSiteFormInputs]?: string | null;
} & {
  misc?: string;
};

export const diveSiteFormValidator = (values: Partial<DiveSiteFormInputs>) => {
  return {
    country_id: !values.country_id ? "Please select a country" : null,
    location: !values.country_id
      ? "Please select a country first"
      : !values.location
      ? "Please select a location"
      : null,
    place_id: !values.place_id
      ? "Please select a location from the list"
      : null,
    dive_site_name: !values.dive_site_name
      ? "Please enter the dive site name"
      : null,
    latitude: !values.latitude
      ? "Please select a location from the list"
      : null,
    longitude: !values.longitude
      ? "Please select a location from the list"
      : null,
  };
};
