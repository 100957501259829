import * as Sentry from "@sentry/remix";
// eslint-disable-next-line import/no-named-as-default
import posthog from "posthog-js";
import { useEffect, useCallback, useState, useRef, useMemo } from "react";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  json,
  useLoaderData,
  useLocation,
  useMatches,
  useRevalidator,
  useRouteError,
} from "@remix-run/react";
import { Session, SupabaseClient } from "@supabase/supabase-js";
import { createBrowserClient } from "@supabase/ssr";
import { type Uppy } from "@uppy/core";
import cookie from "cookie";
import { IconInfoCircle } from "@tabler/icons-react";
import {
  MetaFunction,
  redirect,
  type LinksFunction,
  type LoaderFunctionArgs,
} from "@remix-run/node";
import { ColorSchemeScript, MantineProvider } from "@mantine/core";
import { Notifications, notifications } from "@mantine/notifications";
import { getSupabaseServerClient } from "./utils/auth";
import { AppHeader } from "~/components/AppHeader/AppHeader";
import {
  MAX_IMAGE_SIZE,
  MAX_UPLOADS,
  USER_PREFERENCES_KEY,
} from "~/config/misc";
import { PROFILE } from "~/config/routes";
import { type ModalTypes, MODALS } from "~/config/modals";
import { RootModals } from "~/components/RootModals/RootModals";
import { useUpdatePreferences } from "~/utils/hooks/useUpdatePreferences";
import type { ModalData, PrefetchTypes, UserProfile } from "~/types/shared";
import {
  cookiesColorSchemeManager,
  cssVariablesResolver,
  theme,
  vars,
} from "~/config/theme";
import {
  getAvatarImageUrls,
  getHeroImageUrls,
} from "./utils/getTransformedImageUrls";
import { DIVE_LOG_LIST_TAB, DIVE_LOG_MODAL_TAB } from "~/config/cookie-keys";
import {
  DIVE_LOG_LIST_TAB_TYPES,
  SITE_VIEW,
} from "~/components/DiveSiteLogBookCombo/DiveSiteLogBookCombo";
import {
  DIVE_LOG_MODAL_TABS_TYPES,
  OVERVIEW,
} from "~/components/modals/DiveLogModal/DiveLogModal";
import { Container } from "~/components/_common/Container/Container";
import { Paper } from "~/components/_common/Paper/Paper";
import { Footer } from "~/components/Footer/Footer";

const defaultPreferences = {
  preferred_temperature_unit: "celsius",
  preferred_depth_unit: "metres",
  preferred_pressure_unit: "psi",
  preferred_weight_unit: "kg",
  preferred_volume_unit: "litres",
} as const;

import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import "@mantine/notifications/styles.css";
import "./global-styles.css";

const COLOR_SCHEME_KEY = "scuba-theme";

const colorSchemeManager = cookiesColorSchemeManager({
  key: COLOR_SCHEME_KEY,
});

export const meta: MetaFunction = () => {
  return [
    { title: "Scuba Network" },
    {
      name: "description",
      content:
        "The ultimate dive log and social platform. Connect, share and let our dive logs drive insights. On a mission to empower divers and protect our oceans.",
    },
  ];
};

export const links: LinksFunction = () => {
  return [
    {
      rel: "preconnect",
      href: "https://fonts.googleapis.com",
    },
    {
      rel: "preconnect",
      href: "https://fonts.gstatic.com",
      crossOrigin: "anonymous",
    },
    {
      href: "https://fonts.googleapis.com/css2?family=Rubik:wght@400..500&display=swap",
      rel: "stylesheet",
    },
    {
      href: "/favicon.svg",
      rel: "icon",
      type: "image/svg+xml",
    },
    {
      href: "/favicon.svg",
      rel: "icon",
      type: "image/svg+xml",
    },
  ];
};

export const loader = async ({ request, params }: LoaderFunctionArgs) => {
  const env = {
    SUPABASE_URL: process.env.SUPABASE_URL!,
    SUPABASE_ANON_KEY: process.env.SUPABASE_ANON_KEY!,
    GOOGLE_MAPS_API_KEY: process.env.GOOGLE_MAPS_API_KEY!,
    SENTRY_DSN: process.env.SENTRY_DSN!,
    POSTHOG_API_KEY: process.env.POSTHOG_API_KEY!,
  };

  const { supabaseClient } = getSupabaseServerClient({ request });

  const {
    data: { session },
  } = await supabaseClient.auth.getSession();

  const cookiesString = request.headers.get("Cookie") || "";
  const cookies = cookie.parse(cookiesString);

  const diveLogListTab = (cookies?.[DIVE_LOG_LIST_TAB] ||
    SITE_VIEW) as DIVE_LOG_LIST_TAB_TYPES;
  const diveLogModalTab = (cookies?.[DIVE_LOG_MODAL_TAB] ||
    OVERVIEW) as DIVE_LOG_MODAL_TABS_TYPES;

  const colorScheme = (cookies?.[COLOR_SCHEME_KEY] || "dark") as
    | "dark"
    | "light";

  let userProfile: UserProfile = cookies?.[USER_PREFERENCES_KEY]
    ? { ...defaultPreferences, ...JSON.parse(cookies?.[USER_PREFERENCES_KEY]) }
    : defaultPreferences;

  let userProfileWithImageUrls: UserProfile | null = null;

  if (session) {
    const { data: profileData, error: profileError } = await supabaseClient
      .from("user_profiles_view")
      .select("*, user_role: user_roles(role)")
      .eq("id", session.user.id);

    if (profileError) {
      console.error("User profile not yet created: ", profileError);
    }

    if (profileData?.length) {
      const user_role = profileData?.[0]?.user_role.length
        ? profileData?.[0]?.user_role[0].role
        : null;

      userProfile = {
        ...userProfile,
        ...(profileData?.[0] || {}),
        user_role,
      } as unknown as UserProfile;
    } else if (!params.profileId) {
      return redirect(`${PROFILE}/${session.user.id}`);
    }

    userProfileWithImageUrls = {
      ...userProfile,
      hero: userProfile.hero_image_name
        ? getHeroImageUrls({
            data: { name: userProfile.hero_image_name, id: userProfile.id },
            supabaseClient,
          })
        : null,
      avatar: userProfile.avatar_image_name
        ? getAvatarImageUrls({
            data: { name: userProfile.avatar_image_name },
            supabaseClient,
          })
        : null,
    };
  }

  const response = new Response();

  return json(
    {
      env,
      session,
      colorScheme,
      userProfile: userProfileWithImageUrls ?? userProfile,
      diveLogListTab,
      diveLogModalTab,
    },
    {
      headers: response.headers,
    }
  );
};

export function Layout({ children }: { children: React.ReactNode }) {
  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
        {process.env.NODE_ENV !== "production" ? (
          <meta name="robots" content="noindex, nofollow" />
        ) : null}
        <Meta />
        <Links />
      </head>
      <body>
        {children}
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

function AppShell({
  children,
  session,
  userProfile,
  supabase,
  setCurrentModal,
  hasError,
  supabaseUrl,
}: {
  children: React.ReactNode;
  session?: Session | null;
  userProfile?: UserProfile;
  supabase?: SupabaseClient;
  setCurrentModal?: (modal: ModalTypes | null) => void;
  hasError?: boolean;
  supabaseUrl?: string;
}) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100dvh",
        justifyContent: "space-between",
      }}
    >
      <AppHeader
        imageBasePath={supabaseUrl}
        supabase={supabase}
        user={session?.user}
        onCreateModalOpen={() => {
          if (session && !userProfile?.username) {
            setCurrentModal?.(MODALS.PREFERENCES);
            notifications.clean();
            notifications.show({
              message:
                "You must complete your profile before you can create and upload content",
              color: vars.colors.dark[6],
              icon: <IconInfoCircle />,
              autoClose: 10_000,
            });
          } else {
            setCurrentModal?.(MODALS.ADD_CREATE);
          }
        }}
        hasError={hasError}
        userProfile={userProfile}
        setCurrentModal={setCurrentModal}
      />
      {children}
    </div>
  );
}

function App() {
  const [isUploadingFiles, setIsUploadingFiles] = useState(false);
  const [uploadComplete, setUploadComplete] = useState(false);
  const [modalHistory, setModalHistory] = useState<ModalTypes[]>([]);
  const [modalData, setModalData] = useState<ModalData>(null);
  const [shiftKeyPressed, setShiftKeyPressed] = useState(false);
  const {
    env,
    session,
    colorScheme,
    userProfile,
    diveLogListTab,
    diveLogModalTab: initialDiveLogModalTab,
  } = useLoaderData<typeof loader>() || {};

  const [diveSiteLogbookTab, setDiveSiteLogbookTab] =
    useState<DIVE_LOG_LIST_TAB_TYPES>(diveLogListTab);
  const [diveLogModalTab, setDiveLogModalTab] =
    useState<DIVE_LOG_MODAL_TABS_TYPES>(initialDiveLogModalTab);

  const [prefetchType, setPrefetchType] = useState<PrefetchTypes>("none");

  const sentryInitialised = useRef(false);

  useEffect(() => {
    const isTouchDevice = window.matchMedia(
      "(hover: none) and (pointer: coarse)"
    ).matches;

    // Prefetch on hover for non-touch devices, otherwise once within viewport
    if (!isTouchDevice) {
      setPrefetchType("intent");
    } else {
      setPrefetchType("viewport");
    }
  }, []);

  const {
    handleToggleTempUnit,
    handleToggleDepthUnit,
    handleToggleWeightUnit,
    handleTogglePressureUnit,
    handleToggleVolumeUnit,
    optimisticUserProfile,
    syncPreferences,
  } = useUpdatePreferences({
    initialProfile: userProfile as UserProfile,
  });
  const { revalidate } = useRevalidator();
  const [supabase] = useState(() =>
    createBrowserClient(env.SUPABASE_URL, env.SUPABASE_ANON_KEY)
  );
  const [uppy, setUppy] = useState<Uppy>();
  const accessToken = session?.access_token;

  const goToPreviousModal = useCallback(
    (count = 1, data?: ModalData) => {
      const clonedHistory = [...modalHistory];
      clonedHistory.splice(modalHistory.length - count, count + 2);
      setModalHistory(clonedHistory);
      setModalData(data);
    },
    [modalHistory]
  );

  const setCurrentModal = useCallback(
    (modal: ModalTypes | null, data?: ModalData) => {
      if (modal) {
        setModalHistory((history) => [...history, modal]);
        setModalData(data);
      } else {
        setModalHistory([]);
        setModalData(null);
      }
    },
    []
  );

  const closeModal = useCallback(() => {
    setModalHistory([]);
    setModalData(null);
  }, []);

  const currentModal = modalHistory[modalHistory.length - 1];

  useEffect(() => {
    if (!uppy && accessToken) {
      (async () => {
        const Tus = await import("@uppy/tus");
        const { Uppy } = await import("@uppy/core");
        const Compressor = await import("@uppy/compressor");
        const GoldenRetriever = await import("@uppy/golden-retriever");

        const newUppy = new Uppy({
          autoProceed: true,
          restrictions: {
            maxFileSize: MAX_IMAGE_SIZE,
            maxNumberOfFiles: MAX_UPLOADS,
            allowedFileTypes: ["image/jpeg", "image/png"],
          },
        });

        newUppy.use(Tus.default, {
          endpoint: `${env.SUPABASE_URL}/storage/v1/upload/resumable`,
          retryDelays: [0, 1000, 3000, 5000],
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
          uploadDataDuringCreation: true,
          removeFingerprintOnSuccess: true, // Important if you want to allow re-uploading the same file https://github.com/tus/tus-js-client/blob/main/docs/api.md#removefingerprintonsuccess
        });

        newUppy.use(Compressor.default);
        newUppy.use(GoldenRetriever.default);

        setUppy(newUppy);

        const onUpload = () => {
          setIsUploadingFiles(true);
          setUploadComplete(false);
        };
        const onSuccess = () => {
          setIsUploadingFiles(false);
          setUploadComplete(true);
        };

        newUppy.on("upload", onUpload);
        newUppy.on("upload-success", onSuccess);

        return () => {
          newUppy.off("upload", onUpload);
          newUppy.off("upload-success", onSuccess);
        };
      })();
    }
  }, [env.SUPABASE_URL, accessToken, uppy]);

  useEffect(() => {
    if (currentModal !== MODALS.UPLOAD_DIVE_PHOTOS) {
      uppy?.cancelAll();
    }
  }, [currentModal, uppy]);

  useEffect(() => {
    const handleOnKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Shift") {
        setShiftKeyPressed(true);
      }
    };

    const handleKeyUp = (e: KeyboardEvent) => {
      if (e.key === "Shift") {
        setShiftKeyPressed(false);
      }
    };

    document.body.addEventListener("keydown", handleOnKeyDown);
    document.body.addEventListener("keyup", handleKeyUp);

    return () => {
      document.body.removeEventListener("keydown", handleOnKeyDown);
      document.body.removeEventListener("keyup", handleKeyUp);
    };
  });

  useEffect(() => {
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((event, session) => {
      if (
        event !== "INITIAL_SESSION" &&
        session?.access_token !== accessToken
      ) {
        console.log("Syncing client and server...");
        revalidate();
        syncPreferences();
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [revalidate, syncPreferences, accessToken, supabase]);

  useEffect(() => {
    if (sentryInitialised.current) return;

    sentryInitialised.current = true;

    Sentry.init({
      dsn: env.SENTRY_DSN,
      tracesSampleRate: 1,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1,

      integrations: [
        Sentry.browserTracingIntegration({
          useEffect,
          useLocation,
          useMatches,
        }),
        Sentry!.replayIntegration(),
      ],
    });
  }, [env.SENTRY_DSN]);

  useEffect(() => {
    if (env.POSTHOG_API_KEY) {
      posthog.init(env.POSTHOG_API_KEY, {
        api_host: "https://eu.i.posthog.com",
      });
    }
  }, [env.POSTHOG_API_KEY]);

  const outletContext = useMemo(() => {
    return {
      supabaseUrl: env.SUPABASE_URL,
      supabase,
      uppy,
      session: session as {
        accessToken: string;
        user: {
          id: string;
        };
      } | null,
      currentModal,
      setCurrentModal,
      modalData,
      closeModal,
      userProfile: optimisticUserProfile as UserProfile,
      handleToggleTempUnit,
      handleToggleDepthUnit,
      handleToggleWeightUnit,
      handleTogglePressureUnit,
      handleToggleVolumeUnit,
      syncPreferences,
      goToPreviousModal,
      modalHistory,
      isUploadingFiles,
      uploadComplete,
      setUploadComplete,
      prefetchType,
      diveLogListTab,
      diveLogModalTab,
      setDiveLogModalTab,
      diveSiteLogbookTab,
      setDiveSiteLogbookTab,
      shiftKeyPressed,
    };
  }, [
    env.SUPABASE_URL,
    supabase,
    uppy,
    session,
    currentModal,
    setCurrentModal,
    modalData,
    closeModal,
    optimisticUserProfile,
    handleToggleTempUnit,
    handleToggleDepthUnit,
    handleToggleWeightUnit,
    handleTogglePressureUnit,
    handleToggleVolumeUnit,
    syncPreferences,
    goToPreviousModal,
    modalHistory,
    isUploadingFiles,
    uploadComplete,
    prefetchType,
    diveLogListTab,
    diveLogModalTab,
    diveSiteLogbookTab,
    shiftKeyPressed,
  ]);

  return (
    <>
      <ColorSchemeScript defaultColorScheme={colorScheme} />
      <script
        defer
        src={`https://maps.googleapis.com/maps/api/js?key=${env.GOOGLE_MAPS_API_KEY}&libraries=places`}
      ></script>
      <MantineProvider
        theme={theme}
        defaultColorScheme={colorScheme}
        colorSchemeManager={colorSchemeManager}
        cssVariablesResolver={cssVariablesResolver}
      >
        <AppShell
          session={session}
          userProfile={userProfile as UserProfile}
          supabase={supabase}
          supabaseUrl={env.SUPABASE_URL}
          setCurrentModal={setCurrentModal}
        >
          <Outlet context={outletContext} />
          <Footer prefetchType={prefetchType} />
        </AppShell>
        <RootModals outletContext={outletContext} />
        <Notifications position="bottom-right" />
      </MantineProvider>
    </>
  );
}

export default Sentry.withSentry(App);

export function ErrorBoundary() {
  const error = useRouteError();

  Sentry.captureRemixErrorBoundaryError(error);

  console.error(error);

  // Must not use any Mantine components in here as they cause an infinite loop of errors...

  return (
    <AppShell hasError>
      <Paper centerX centerY spaceY fullHeight>
        <Container>
          <h1>We seem to have a problem...</h1>
          <p>
            Please check your internet connection and try again. If the problem
            persists, please contact support.
          </p>
        </Container>
      </Paper>
    </AppShell>
  );
}
