import { useEffect, useState } from "react";
import { useMatches } from "@remix-run/react";
import { AddCreateModal } from "~/components/AddCreateWizard/AddCreateModal/AddCreateModal";
import { TripModal } from "~/components/AddCreateWizard/TripModal/TripModal";
import { ProfileModal } from "~/components/modals/ProfileModal/ProfileModal";
import { LogBookUpload } from "~/components/AddCreateWizard/LogBookUpload/LogBookUpload";
import { UploadDiveLogModal } from "~/components/AddCreateWizard/UploadDiveLogModal/UploadDiveLogModal";
import { DiveSiteModal } from "~/components/AddCreateWizard/DiveSiteModal/DiveSiteModal";
import { UploadPhotosModal } from "~/components/AddCreateWizard/UploadPhotosModal/UploadPhotosModal";
import { UnitsModal } from "~/components/modals/UnitsModal/UnitsModal";
import { MODALS } from "~/config/modals";
import type {
  HeroImage,
  CompanyLogo,
  DiveCompany,
  LoggedDive,
  LoggedTrip,
  OutletContext,
  PartialLoggedTrip,
  UserProfile,
  DiveSiteType,
} from "~/types/shared";
import { CreateDiveCompany } from "../AddCreateWizard/CreateDiveCompany/CreateDiveCompany";

export function RootModals({
  outletContext,
}: {
  outletContext: OutletContext;
}) {
  const {
    setCurrentModal,
    currentModal,
    modalData,
    goToPreviousModal,
    modalHistory,
    closeModal,
    userProfile,
    session,
    setDiveLogModalTab,
    shiftKeyPressed,
  } = outletContext;
  const { loggedTrip, loggedTrips, diveCompany, diveSite } =
    useMatches().reduce(
      (acc, curr) => {
        const { data } = curr;
        if (typeof data !== "object") return acc;

        const extra: {
          loggedTrips?: PartialLoggedTrip[];
          loggedDives?: LoggedDive[];
          loggedTrip?: LoggedTrip;
          diveCompany?: DiveCompany & {
            companyHero?: HeroImage;
            companyLogo?: CompanyLogo;
          };
          diveSite?: DiveSiteType;
        } = {};

        if (data && "userProfile" in data) {
          extra.loggedTrips = (data?.userProfile as UserProfile | null)
            ?.logged_trips as PartialLoggedTrip[];
        }

        if (
          data &&
          "logged_dives" in data &&
          Array.isArray(data?.logged_dives)
        ) {
          extra.loggedDives = data?.logged_dives as LoggedDive[];
        }

        if (data && "logged_trip" in data) {
          if ("logged_dives" in data) {
            extra.loggedDives = data.logged_dives as LoggedDive[];
          }

          extra.loggedTrip = data.logged_trip as LoggedTrip;
        }

        if (data && "diveCompany" in data && Array.isArray(data?.diveCompany)) {
          extra.diveCompany = data?.diveCompany[0] as DiveCompany;

          if ("companyHero" in data && typeof data.companyHero === "object") {
            extra.diveCompany.companyHero = data.companyHero as HeroImage;
          }

          if ("companyLogo" in data && typeof data.companyLogo === "object") {
            extra.diveCompany.companyLogo = data.companyLogo as CompanyLogo;
          }
        }

        if (data && "diveSite" in data) {
          extra.diveSite = data?.diveSite as DiveSiteType;
        }

        return { ...acc, ...extra };
      },
      { loggedTrips: [], loggedDives: [] } as {
        loggedTrips: PartialLoggedTrip[];
        loggedDives: LoggedDive[];
        loggedTrip?: LoggedTrip;
        diveCompany?: DiveCompany & {
          companyHero?: HeroImage;
          companyLogo?: CompanyLogo;
        };
        diveSite?: DiveSiteType;
      }
    );

  const [newTripId, setNewTripId] = useState<string>();
  const [newDiveLogId, setNewDiveLogId] = useState<string>();
  const [tempCountry, setTempCountry] = useState<{
    country: string;
    country_id: string;
    country_code: string;
  } | null>(null);

  useEffect(() => {
    if (!currentModal) {
      setNewTripId("");
      setNewDiveLogId("");
    }
  }, [currentModal]);

  return (
    <>
      <AddCreateModal
        modalHistory={modalHistory}
        modalType={MODALS.ADD_CREATE}
        setCurrentModal={setCurrentModal}
        isAuthenticated={Boolean(session?.user.id)}
        currentUserId={userProfile.id}
      />
      <CreateDiveCompany
        modalHistory={modalHistory}
        modalType={MODALS.CREATE_ORGANISATION}
        goBack={goToPreviousModal}
        onClose={closeModal}
        initialValues={{ ...modalData }}
        newCountryValues={tempCountry}
        outletContext={outletContext}
      />
      <CreateDiveCompany
        modalHistory={modalHistory}
        modalType={MODALS.ORGANISATION_EDIT}
        goBack={goToPreviousModal}
        onClose={closeModal}
        initialValues={diveCompany}
        newCountryValues={tempCountry}
        outletContext={outletContext}
      />
      <TripModal
        modalHistory={modalHistory}
        modalType={MODALS.CREATE_TRIP}
        setCurrentModal={setCurrentModal}
        currentModal={currentModal}
        goBack={goToPreviousModal}
        setNewTripId={setNewTripId}
        setTempCountry={setTempCountry}
        modalData={modalData}
      />
      <TripModal
        modalHistory={modalHistory}
        modalType={MODALS.EDIT_TRIP}
        setCurrentModal={setCurrentModal}
        currentModal={currentModal}
        goBack={goToPreviousModal}
        initialValues={loggedTrip}
        setNewTripId={setNewTripId}
        setTempCountry={setTempCountry}
        modalData={modalData}
      />
      <UploadDiveLogModal
        modalHistory={modalHistory}
        modalType={MODALS.DIVE_LOG}
        onClose={closeModal}
        goBack={goToPreviousModal}
        userProfile={userProfile}
        setCurrentModal={setCurrentModal}
        modalData={modalData}
        setDiveLogModalTab={setDiveLogModalTab}
        shiftKeyPressed={shiftKeyPressed}
      />
      <DiveSiteModal
        modalType={MODALS.DIVE_SITE}
        modalHistory={modalHistory}
        initialValues={{ ...modalData }}
        goBack={goToPreviousModal}
        onClose={closeModal}
      />
      <DiveSiteModal
        modalType={MODALS.DIVE_SITE_EDIT}
        modalHistory={modalHistory}
        initialValues={{ ...modalData, ...diveSite }}
        goBack={goToPreviousModal}
        onClose={closeModal}
      />
      <LogBookUpload
        modalHistory={modalHistory}
        goBack={goToPreviousModal}
        currentUserId={userProfile.id}
        onClose={closeModal}
      />
      <ProfileModal
        modalHistory={modalHistory}
        setCurrentModal={setCurrentModal}
        userProfile={userProfile}
      />
      <UploadPhotosModal
        modalHistory={modalHistory}
        modalType={MODALS.UPLOAD_DIVE_PHOTOS}
        setCurrentModal={setCurrentModal}
        currentModal={currentModal}
        onClose={closeModal}
        outletContext={outletContext}
        loggedTrips={loggedTrips}
        setSelectedTripId={setNewTripId}
        newTripId={newTripId}
        newDiveLogId={newDiveLogId}
        currentUserId={userProfile.id}
      />
      <UnitsModal
        modalHistory={modalHistory}
        setCurrentModal={setCurrentModal}
        outletContext={outletContext}
        userProfile={userProfile}
      />
    </>
  );
}
