import dayjs from "dayjs";
import { XMLParser } from "fast-xml-parser";
import { DiveSample, SAMPLE_RATE } from "~/config/dive-log";
import { kelvinToCelcius } from "~/utils/conversions";

type DiveSite = {
  "@_id": string;
  name: string;
  geography: {
    address: {
      country: string;
    };
    location: string;
    latitude: number;
    longitude: number;
  };
};

export type UDDF = {
  samples?: {
    waypoint: {
      temperature: number;
      divetime: number;
      depth: number;
      tankpressure: number;
      calculatedpo2: number;
      cns: number;
      nodecotime: number;
    }[];
  };
  informationbeforedive: {
    datetime: string;
    divenumber: number;
    link?: {
      "@_ref": string;
    };
  };
  informationafterdive: {
    lowesttemperature: number;
    diveduration: number;
    greatestdepth: number;
  };
  tankdata?: {
    tankpressurebegin: number;
    tankpressureend: number;
  };
  diveSite?: DiveSite;
};

const parserOptions = {
  ignoreAttributes: false,
};

export const uddfParser = async ({ file }: { file: File }) => {
  const parser = new XMLParser(parserOptions);

  const xml = await file.text();
  const data = parser.parse(xml);

  const { uddf } = data;

  // Todo: Use generator info to detemine how best to parse the data

  const sites = uddf?.divesite?.site;

  const diveSites: {
    [key: string]: DiveSite;
  } = Array.isArray(sites)
    ? sites.reduce(
        (
          acc: {
            [key: string]: DiveSite;
          },
          curr: DiveSite
        ) => {
          const siteId = curr["@_id"];
          return {
            ...acc,
            [siteId]: curr,
          };
        },
        {}
      )
    : sites
    ? {
        [sites["@_id"]]: sites,
      }
    : {};

  const dives =
    uddf?.profiledata?.repetitiongroup.dive ||
    (uddf?.profiledata?.repetitiongroup as []).flatMap(({ dive }) => dive);

  if (Array.isArray(dives)) {
    return dives.map((dive: UDDF) => {
      const link = dive.informationbeforedive?.link;

      if (Array.isArray(link)) {
        link.every((l) => {
          const id = l["@_ref"];
          const diveSite = id && diveSites[id];

          if (diveSite) {
            dive.diveSite = diveSite;
            return false;
          }

          return true;
        });
      } else {
        const siteId = dive.informationbeforedive?.link?.["@_ref"];
        const diveSite = siteId && diveSites[siteId];

        if (diveSite) {
          dive.diveSite = diveSite;
        }
      }

      return dive;
    }) as UDDF[];
  }

  const diveSiteId = dives.informationbeforedive?.link?.["@_ref"];

  return {
    ...dives,
    diveSite: diveSiteId && diveSites[diveSiteId],
  } as UDDF;
};

export const uddfFormatter = ({
  samples,
  informationbeforedive,
  informationafterdive,
  tankdata,
  diveSite,
}: UDDF) => {
  const { datetime } = informationbeforedive || {};
  const { diveduration, greatestdepth } = informationafterdive || {};

  let min_water_temp = Infinity;
  let max_water_temp = -Infinity;

  const entry_date = datetime && dayjs(datetime).format("YYYY-MM-DD");
  const entry_time = datetime && dayjs(datetime).format("HH:mm");

  const isDurationInSeconds = diveduration > 90; // Todo: something better than this!

  const dive_duration = isDurationInSeconds
    ? Math.round(diveduration / 60)
    : diveduration;
  const max_depth = greatestdepth;

  let pressure_start = tankdata?.tankpressurebegin;
  let pressure_end = tankdata?.tankpressureend;

  const dive_samples: DiveSample[] = [];

  samples?.waypoint.forEach(
    ({
      temperature,
      divetime,
      depth,
      tankpressure,
      calculatedpo2,
      cns,
      nodecotime,
    }: {
      temperature: number;
      divetime: number;
      depth: number;
      tankpressure: number;
      calculatedpo2: number;
      cns: number;
      nodecotime: number;
    }) => {
      if (temperature < min_water_temp) {
        min_water_temp = temperature;
      }
      if (temperature > max_water_temp) {
        max_water_temp = temperature;
      }

      const lastSampleTime =
        dive_samples[dive_samples.length - 1]?.elapsed_time;
      const timeDiff =
        typeof lastSampleTime === "number"
          ? divetime - lastSampleTime
          : Infinity;

      if (timeDiff >= SAMPLE_RATE) {
        dive_samples.push({
          water_temp: kelvinToCelcius(temperature),
          depth,
          tank_pressure:
            typeof tankpressure === "number"
              ? Math.round(tankpressure / 100_000)
              : undefined,
          elapsed_time: divetime,
          po2: calculatedpo2,
          cns,
          no_deco_time: nodecotime,
          // timestamp,
          // time: dayjs(timestamp).format("HH:mm:ss"),
          // heart_rate,
        });
      }
    }
  );

  const { name, geography } = diveSite || {};
  const { address, location, latitude, longitude } = geography || {};

  // Get the second and second to last readings, as the first and last don't contain the full data
  const firstReading = samples?.waypoint[1].tankpressure;
  const lastReading =
    samples?.waypoint[samples.waypoint.length - 2].tankpressure;

  if (typeof pressure_start !== "number" && typeof firstReading === "number") {
    pressure_start = firstReading;
  }

  if (typeof pressure_end !== "number" && typeof lastReading === "number") {
    pressure_end = lastReading;
  }

  return {
    temp_dive_site_name: name,
    temp_dive_site_location: location,
    temp_dive_site_country: address?.country,
    temp_dive_site_latitude: latitude,
    temp_dive_site_longitude: longitude,
    dive_samples,
    min_water_temp:
      min_water_temp === -Infinity
        ? undefined
        : kelvinToCelcius(min_water_temp),
    max_water_temp:
      max_water_temp === -Infinity
        ? undefined
        : kelvinToCelcius(max_water_temp),
    entry_date,
    entry_time,
    dive_duration,
    max_depth,
    pressure_start:
      typeof pressure_start === "number"
        ? Math.round(pressure_start / 100_000)
        : undefined,
    pressure_end:
      typeof pressure_end === "number"
        ? Math.round(pressure_end / 100_000)
        : undefined,
  };
};
