import { UnstyledButton, type UnstyledButtonProps } from "@mantine/core";
import { IconArrowRight } from "@tabler/icons-react";
import classNames from "classnames";
import * as classes from "./InlineButton.css";

export function InlineButton({
  nowrap,
  ...props
}: UnstyledButtonProps & {
  children: React.ReactNode;
  onClick: () => void;
  nowrap?: boolean;
}) {
  return (
    <UnstyledButton
      className={classNames(classes.button, {
        [classes.nowrap]: nowrap,
      })}
      {...props}
    >
      {props.children}
      <IconArrowRight className={classes.icon} />
    </UnstyledButton>
  );
}
