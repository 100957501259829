import { LinkIcon } from "~/components/_common/LinkIcon/LinkIcon";
import { ButtonIcon } from "../ButtonIcon/ButtonIcon";
import * as classes from "./CTAs.css";
import classNames from "classnames";

type CommonItemProps = {
  keepOpen?: boolean;
  text: string;
  icon?: React.ReactNode;
  iconBefore?: React.ReactNode;
  notAvailable?: boolean;
  secondary?: boolean;
};

export function CTAs({
  items,
  onClose,
  className = "",
}: {
  onClose?: () => void;
  items: (
    | (CommonItemProps & {
        to: string;
      })
    | (CommonItemProps & {
        onClick: () => void;
      })
    | null
  )[];
  className?: string;
}) {
  return (
    <nav className={`${classes.container} ${className}`}>
      {items.map((item) => {
        if (!item) return null;

        if ("onClick" in item) {
          const {
            text,
            icon,
            onClick,
            keepOpen,
            iconBefore,
            notAvailable,
            secondary,
          } = item;

          return (
            <ButtonIcon
              key={text}
              onClick={() => {
                onClick();

                if (!keepOpen && onClose) {
                  onClose();
                }
              }}
              // className={notAvailable ? classes.ctaNotAvailable : classes.cta}
              className={classNames({
                [classes.cta]: !notAvailable,
                [classes.ctaNotAvailable]: notAvailable,
                [classes.ctaSecondary]: secondary,
              })}
            >
              <div className={classes.textWithIconBefore}>
                {iconBefore}
                <span>{text}</span>
              </div>
              {icon}
            </ButtonIcon>
          );
        }

        const { to, text, icon, keepOpen, iconBefore } = item;

        return (
          <LinkIcon
            key={text}
            to={to}
            className={classNames(classes.cta, {
              [classes.ctaSecondary]: item.secondary,
            })}
            onClick={() => {
              if (!keepOpen && onClose) {
                onClose();
              }
            }}
          >
            <div className={classes.textWithIconBefore}>
              {iconBefore}
              <span>{text}</span>
            </div>
            {icon}
          </LinkIcon>
        );
      })}
    </nav>
  );
}
