import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { IconBook, IconInfoCircle, IconUpload } from "@tabler/icons-react";
import { Loader, Text } from "@mantine/core";
import { Dropzone, FileWithPath } from "@mantine/dropzone";
import { notifications } from "@mantine/notifications";
import { MODALS, ModalTypes } from "~/config/modals";
import { WizardModal } from "~/components/AddCreateWizard/WizardModal/WizardModal";
import {
  UDDF,
  uddfFormatter,
  uddfParser,
} from "~/utils/logFileParsers/uddfParser";
import { Checkbox } from "~/components/_common/form/Checkbox/Checkbox";
import { FILE_TYPES } from "~/config/dive-log";
import { Container } from "~/components/_common/form/Container/Container";
import { Button } from "~/components/_common/Button/Button";
import { Row } from "~/components/_common/Row/Row";
import { useFetcher, useNavigate } from "@remix-run/react";
import { action } from "~/routes/_api.insert-dive-logs";
import { PROFILE } from "~/config/routes";
import { vars } from "~/config/theme";
import * as classes from "./LogBookUpload.css";

function DropZoneContent({
  goBack,
  onClose,
  currentUserId,
}: {
  goBack: () => void;
  onClose: () => void;
  currentUserId: string;
}) {
  const navigate = useNavigate();
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [allDiveLogs, setAllDiveLogs] = useState<UDDF[] | null>(null);
  const [selectedLogs, setSelectedLogs] = useState<boolean[]>([]);
  const [uploadComplete, setUploadComplete] = useState(false);
  const fetcher = useFetcher<typeof action>();

  const selectAllLogs = (data: unknown[]) =>
    setSelectedLogs(new Array(data.length).fill(true));

  const allLogsSelected = selectedLogs.length === allDiveLogs?.length;
  const filteredLogs = allDiveLogs?.filter((_, i) => selectedLogs[i]);

  const handleOnDrop = async (files: FileWithPath[]) => {
    try {
      const [file] = files;
      setIsProcessing(true);
      setError(null);

      const type = file.type || file.name.split(".").pop();

      if (type === FILE_TYPES.UDDF) {
        const data = await uddfParser({ file });

        if (Array.isArray(data)) {
          const sortedDiveLogs = data.sort(
            (a, b) =>
              b.informationbeforedive.divenumber -
              a.informationbeforedive.divenumber
          );
          setAllDiveLogs(sortedDiveLogs);
          selectAllLogs(sortedDiveLogs);
        } else {
          setError(
            `This looks like a single dive log. Please go back and select "Log a dive" if you would like to upload single dive logs.`
          );
        }
      } else {
        setError(
          `File type not yet supported. If you're happy to share the file with us, please send it over and we can look in to getting it supported.`
        );
      }
    } catch (error) {
      if (typeof error === "string") {
        setError(error);
      } else if (error instanceof Error) {
        setError(error.message);
      }
    }

    setIsProcessing(false);
  };

  const handleSubmit = () => {
    const formData = new FormData();

    setIsProcessing(true);

    const formattedLogs = filteredLogs?.map((log) => uddfFormatter(log));

    formData.append("dive_logs", JSON.stringify(formattedLogs));

    setIsProcessing(false);

    fetcher.submit(formData, {
      method: "post",
      action: "/insert-dive-logs",
    });
  };

  useEffect(() => {
    if (fetcher.data?.success) {
      setUploadComplete(true);

      notifications.show({
        message:
          "Dive site information pulled from log files are currently not automatically connected to dive sites in our database, meaning these logs will not appear on the dive site pages. Please manually update them to help us enrich the dive site data.",
        color: vars.colors.orange[5],
        icon: <IconInfoCircle />,
        autoClose: false,
      });
    }
  }, [fetcher.data]);

  return (
    <Container
      footer={
        uploadComplete ? (
          <Row flexEnd>
            <Button
              variant="outline"
              disabled={fetcher.state === "submitting"}
              onClick={() => {
                setAllDiveLogs(null);
                setSelectedLogs([]);
                setUploadComplete(false);
              }}
            >
              Upload another log book
            </Button>
            <Button
              variant="default"
              onClick={() => {
                navigate(`${PROFILE}/${currentUserId}`);
                onClose();
              }}
            >
              View dive logs
            </Button>
          </Row>
        ) : (
          <Row flexEnd>
            {allDiveLogs ? (
              <Button
                variant="outline"
                disabled={fetcher.state === "submitting"}
                onClick={() => {
                  setAllDiveLogs(null);
                  setSelectedLogs([]);
                }}
              >
                Cancel
              </Button>
            ) : (
              <Button
                variant="transparent"
                mr="auto"
                disabled={fetcher.state === "submitting" || isProcessing}
                onClick={() => {
                  goBack();
                }}
              >
                Go back
              </Button>
            )}
            <Button
              variant="default"
              loading={fetcher.state === "submitting"}
              disabled={!allDiveLogs || !filteredLogs?.length || isProcessing}
              onClick={handleSubmit}
            >
              Save
            </Button>
          </Row>
        )
      }
    >
      <div className={classes.innerContainer}>
        {uploadComplete && filteredLogs?.length ? (
          <Text inline>
            {filteredLogs.length} dive{" "}
            {filteredLogs.length > 1 ? "logs" : "log"} successfully uploaded 🎉
          </Text>
        ) : allDiveLogs?.length ? (
          <>
            <p className={classes.logListInfo}>
              Please select all the logs you would like to upload:
            </p>
            <Button
              onClick={() => {
                if (allLogsSelected) {
                  setSelectedLogs([]);
                } else {
                  selectAllLogs(allDiveLogs);
                }
              }}
              size="compact-xs"
              className={classes.selectToggle}
            >
              {allLogsSelected ? "Deselect all" : "Select all"}
            </Button>
            <ol className={classes.logList}>
              {allDiveLogs.map((log, i) => {
                if (!log?.informationbeforedive) return null;
                const { divenumber, datetime } = log.informationbeforedive;
                return (
                  <li key={i}>
                    <Checkbox
                      checked={Boolean(selectedLogs[i])}
                      onChange={() => {
                        setSelectedLogs((prev) => {
                          const next = [...prev];
                          next[i] = !next[i];
                          return next;
                        });
                      }}
                      classNames={{
                        body: classes.checkboxBody,
                        inner: classes.checkboxInner,
                        label: classes.checkboxLabel,
                        labelWrapper: classes.checkboxLabelWrapper,
                      }}
                      label={
                        <>
                          <span className={classes.diveLogNumber}>
                            # {divenumber}:{" "}
                          </span>
                          <span className={classes.diveLogDate}>
                            {dayjs(datetime).format("DD MMM YYYY HH:mm")}
                          </span>
                        </>
                      }
                    />
                  </li>
                );
              })}
            </ol>
          </>
        ) : (
          <>
            {isProcessing && !error && <Loader type="bars" />}
            {!isProcessing && (
              <>
                <Dropzone
                  onDrop={handleOnDrop}
                  // onReject={([file]) => {
                  //   if (file.errors[0].code === "file-too-large") {
                  //     setError(
                  //       `File is too large. Max size is ${prettyBytes(
                  //         MAX_LOG_FILE_SIZE
                  //       )}`
                  //     );
                  //   }
                  // }}
                  multiple={false}
                  // maxSize={MAX_LOG_FILE_SIZE}
                  className={classes.dropzone}
                  // disabled={hasErrors || isProcessing}
                />
                <div className={classes.dropZoneContent}>
                  <IconUpload className={classes.uploadIcon} />
                  <Text size="md" inline>
                    Drag your log book here or click to select
                    <Text
                      size="xs"
                      mt="xs"
                      component="span"
                      style={{
                        display: "block",
                      }}
                    >
                      We currently only support .uddf files
                    </Text>
                  </Text>
                </div>
              </>
            )}
          </>
        )}
      </div>
      {(error || fetcher?.data?.error) && (
        <Text c="red" size="sm">
          {error || fetcher?.data?.error}
        </Text>
      )}
    </Container>
  );
}

export function LogBookUpload({
  modalHistory,
  onClose,
  goBack,
  currentUserId,
}: {
  modalHistory: ModalTypes[];
  onClose: () => void;
  goBack: () => void;
  currentUserId: string;
}) {
  return (
    <WizardModal
      modalHistory={modalHistory}
      modalType={MODALS.UPLOAD_LOG_BOOK}
      onClose={onClose}
      titleIcon={<IconBook />}
      title={"Upload log book"}
      // withCloseButton={!showLeaveWarning}
    >
      <DropZoneContent
        goBack={goBack}
        currentUserId={currentUserId}
        onClose={onClose}
      />
    </WizardModal>
  );
}
