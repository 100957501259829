import { useEffect } from "react";
import { Group, Text } from "@mantine/core";
import { type UseFormReturnType } from "@mantine/form";
import { useFetcher, useParams } from "@remix-run/react";
import { Select } from "~/components/_common/form/Select/Select";
import { action } from "~/routes/_api.get-dives";
import { InlineButton } from "~/components/_common/InlineButton/InlineButton";
import { getDateAndTime } from "~/utils/getDateAndTime";
import { MODALS, type ModalTypes } from "~/config/modals";
import { IconCheck } from "@tabler/icons-react";

import * as classes from "./DiveLogSelect.css";
import { GET_DIVES } from "~/config/api";

const defaultOption = {
  value: "",
  label: "-- Select a dive log --",
};

export function DiveLogSelect({
  form,
  setCurrentModal,
  setFetcherState,
  initialDiveLogId,
}: {
  form: UseFormReturnType<{
    log_id: string | undefined;
    trip_id: string | undefined;
  }>;
  setCurrentModal: (modal: ModalTypes) => void;
  setFetcherState: (state: "idle" | "loading" | "submitting") => void;
  hidden?: boolean;
  initialDiveLogId?: string;
}) {
  const { logId } = useParams();
  const tripId = form.values.trip_id;

  const fetcher = useFetcher<typeof action>({
    key: `dive-sites--${tripId || ""}--${initialDiveLogId || ""}`,
  });

  const { submit, state, data } = fetcher;

  useEffect(() => {
    setFetcherState(state);
  }, [state, setFetcherState]);

  useEffect(() => {
    const formData = new FormData();
    if (tripId) {
      formData.append("tripId", tripId);
    }

    submit(formData, {
      method: "post",
      action: GET_DIVES,
    });
  }, [submit, tripId, initialDiveLogId]);

  const { setFieldValue } = form;

  useEffect(() => {
    if (logId) {
      setFieldValue("log_id", logId);
    }
  }, [logId, setFieldValue]);

  useEffect(() => {
    if (data?.results?.length && form.values.log_id) {
      const diveLog = data?.results.find(({ id }) => id === form.values.log_id);

      setFieldValue("dive_site_id", diveLog?.dive_site_id);

      if (diveLog?.trip_id) {
        setFieldValue("temp_trip_id", diveLog.trip_id);
      } else {
        setFieldValue("temp_trip_id", "");
      }
    } else {
      setFieldValue("dive_site_id", "");
      // setFieldValue("temp_trip_id", "");
    }
  }, [form.values.log_id, data?.results, setFieldValue]);

  const formattedData =
    data?.results?.map(
      ({
        id = "",
        dive_sites,
        temp_dive_site_name,
        temp_dive_site_location,
        entry_date,
        entry_time,
        dive_number,
        trip_dive_number,
      }) => {
        const siteName =
          dive_sites?.dive_site_name || temp_dive_site_name || "";
        const location = dive_sites?.location || temp_dive_site_location || "";

        return {
          value: id,
          label: location
            ? `#${trip_dive_number ?? dive_number} ${siteName}, ${location}`
            : `#${trip_dive_number ?? dive_number} ${siteName}`,
          date:
            entry_date &&
            getDateAndTime({
              date: entry_date,
              time: entry_time,
            }),
        };
      }
    ) || [];

  return (
    <div>
      <Select
        {...form.getInputProps("log_id")}
        key={tripId}
        name="log_id"
        label="Dive logs"
        loading={state !== "idle"}
        allowDeselect
        disabled={!data?.results?.length}
        placeholder={
          data?.results?.length
            ? "Select a dive log..."
            : "No dives logged for this trip"
        }
        data={[defaultOption, ...formattedData]}
        renderOption={({ option }) => {
          const isSelected = form.values.log_id === option.value;

          if (!option?.value) {
            return (
              <Group gap="sm">
                <div>
                  <Text size="sm">{defaultOption.label}</Text>
                </div>
              </Group>
            );
          }

          return (
            <Group gap="sm" className={classes.itemContainer}>
              <div className={classes.itemText}>
                <Text size="sm">{option.label}</Text>
                <Text size="xs" opacity={0.7}>
                  {/* @ts-expect-error - ? */}
                  {option.date}
                </Text>
              </div>
              {isSelected && <IconCheck size="25" />}
            </Group>
          );
        }}
        // error={allErrors.trip_type}
      />
      <InlineButton onClick={() => setCurrentModal(MODALS.DIVE_LOG)}>
        Dive not logged yet? Click here to add it
      </InlineButton>
    </div>
  );
}
