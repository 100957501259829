import { type LoaderFunctionArgs } from "@remix-run/node";
import { createServerClient, parse, serialize } from "@supabase/ssr";
import { createClient } from "@supabase/supabase-js";
import { type Database } from "~/types/supabase";

export const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

// Regex for at least 8 characters, lowercase, uppercase and numbers and special characters
export const PASSWORD_REGEX =
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;

// Regex for 3-20 characters, lowercase, alphanumeric and underscores
export const USERNAME_REGEX = /^[a-z0-9_]{3,20}$/;

// Regex for 1-50 characters, letters and spaces
export const NAME_REGEX = /^[a-zA-Z\s]{1,50}$/;

export const getSupabaseServerClient = ({
  request,
  useServiceRole,
}: Pick<LoaderFunctionArgs, "request"> & { useServiceRole?: boolean }) => {
  const cookies = parse(request.headers.get("Cookie") ?? "");
  const headers = new Headers();

  if (!process.env.SUPABASE_URL || !process.env.SUPABASE_ANON_KEY) {
    throw new Error("Missing SUPABASE_URL or SUPABASE_ANON_KEY");
  }

  const supabaseClient = createServerClient<Database>(
    process.env.SUPABASE_URL,
    useServiceRole
      ? process.env.SUPABASE_SERVICE_ROLE_KEY!
      : process.env.SUPABASE_ANON_KEY,
    {
      cookies: {
        get(key) {
          return cookies[key];
        },
        set(key, value, options) {
          headers.append("Set-Cookie", serialize(key, value, options));
        },
        remove(key, options) {
          headers.append("Set-Cookie", serialize(key, "", options));
        },
      },
    }
  );
  return { supabaseClient, headers };
};

/** *** Never to be exposed to client / users!!!! *** */
export const getSupabaseAdminClient = () => {
  if (!process.env.SUPABASE_URL || !process.env.SUPABASE_SERVICE_ROLE_KEY) {
    throw new Error("Missing SUPABASE_URL or SUPABASE_SERVICE_ROLE_KEY");
  }

  return createClient(
    process.env.SUPABASE_URL,
    process.env.SUPABASE_SERVICE_ROLE_KEY
  );
};

/** For use on server only. Uses session data from cookies, so not to be trusted... */
export const getSession = async ({
  request,
}: Pick<LoaderFunctionArgs, "request">) => {
  const { supabaseClient } = getSupabaseServerClient({ request });
  const {
    data: { session },
  } = await supabaseClient.auth.getSession();

  return session;
};

/** For use on server only. Queries DB therefor can be trusted */
export const getRealUser = async ({
  request,
}: Pick<LoaderFunctionArgs, "request">) => {
  const { supabaseClient } = getSupabaseServerClient({ request });
  const {
    data: { user },
  } = await supabaseClient.auth.getUser();

  return user;
};
