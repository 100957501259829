import { FormEvent, useCallback, useEffect, useRef, useState } from "react";
import { IconMapPin } from "@tabler/icons-react";
import { useFetcher } from "@remix-run/react";
import { useForm } from "@mantine/form";
import { Title } from "@mantine/core";
import { WizardModal } from "~/components/AddCreateWizard/WizardModal/WizardModal";
import { Form } from "~/components/_common/form/Form/Form";
import { Button } from "~/components/_common/Button/Button";
import { Row } from "~/components/_common/Row/Row";
import { LocationSearchClient } from "~/components/LocationSearch/LocationSearch";
import { TextInput } from "~/components/_common/form/TextInput/TextInput";
import { ErrorText } from "~/components/_common/form/ErrorText/ErrorText";
import {
  diveSiteFormValidator,
  type DiveSiteErrors,
  type DiveSiteFormInputs,
} from "~/config/dive-site";
import { action } from "~/routes/_api.create-dive-site";
import { MODALS, ModalTypes } from "~/config/modals";
import { Container } from "~/components/_common/form/Container/Container";
import { CountrySearch } from "~/components/CountrySearch/CountrySearch";
import { DiveSiteType, ModalData } from "~/types/shared";
import { showNotication } from "~/config/notifications";

function FormStuff({
  initialValues,
  onGoBack,
  onCheckGoBack,
  showLeaveWarning,
  setShowLeaveWarning,
  handleClose,
  setIsFormDirty,
  prevModal,
}: {
  initialValues: Partial<DiveSiteType>;
  onGoBack: (count?: number, data?: ModalData) => void;
  onCheckGoBack: (count?: number, data?: ModalData) => void;
  showLeaveWarning: "close" | "go-back" | null;
  setShowLeaveWarning: (value: "close" | "go-back" | null) => void;
  handleClose: () => void;
  setIsFormDirty: (value: boolean) => void;
  prevModal: ModalTypes;
}) {
  const fetcher = useFetcher<typeof action>();

  const formRef = useRef<HTMLFormElement | null>(null);
  const form = useForm<Partial<DiveSiteFormInputs>>({
    initialValues: {
      dive_site_name: "",
      location: "",
      place_id: "",
      country_code: initialValues.alpha_2 ?? "",
      ...initialValues,
    },
    validate: diveSiteFormValidator,
  });
  const { errors } = fetcher.data ?? { errors: {} };
  const allErrors: DiveSiteErrors =
    fetcher.state === "submitting" ? {} : { ...errors, ...form.errors };
  const isFormDirty = form.isDirty();
  const hasErrors = Object.keys(allErrors).length > 0;

  useEffect(() => {
    setIsFormDirty(isFormDirty);
  }, [setIsFormDirty, isFormDirty]);

  const handleSubmit = (e?: FormEvent) => {
    e?.preventDefault();
    form.clearErrors();

    const { hasErrors: localErrors } = form.validate();

    if (!localErrors && formRef?.current) {
      const formData = new FormData(formRef.current);

      if (initialValues.id) {
        formData.append("id", initialValues.id);
      }

      fetcher.submit(formData, {
        method: "post",
        action: "/create-dive-site",
      });
    }
  };

  const { setFieldValue } = form;

  useEffect(
    function onSuccess() {
      if (
        fetcher.state === "idle" &&
        fetcher.data?.diveSiteId &&
        !hasErrors &&
        isFormDirty
      ) {
        const action = initialValues.id ? "updated" : "created";
        showNotication(`Dive site successfully ${action}`, "success", true);

        form.reset();

        onGoBack(1, {
          dive_site_name: fetcher.data.diveSiteName ?? "",
        });
      }
    },
    [
      initialValues.id,
      fetcher.state,
      fetcher.data,
      hasErrors,
      isFormDirty,
      form,
      onGoBack,
      setIsFormDirty,
    ]
  );

  const handleSetCountryFields = useCallback(
    (countryId: string, countryCode: string) => {
      setFieldValue("country_id", countryId);
      setFieldValue("country_code", countryCode);
    },
    [setFieldValue]
  );

  const handleResetCountryFields = useCallback(() => {
    setFieldValue("country_code", "");
    setFieldValue("country_id", "");
  }, [setFieldValue]);

  return !showLeaveWarning ? (
    <Form ref={formRef}>
      <Container
        footer={
          <>
            <Row flexEnd>
              <Button
                variant="transparent"
                mr="auto"
                disabled={fetcher.state === "submitting"}
                onClick={() => onCheckGoBack()}
              >
                {prevModal ? "Go back" : "Cancel"}
              </Button>
              <Button
                variant="default"
                loading={fetcher.state === "submitting"}
                disabled={fetcher.state === "submitting" || !isFormDirty}
                onClick={() => {
                  handleSubmit();
                }}
              >
                Save
              </Button>
            </Row>
            <ErrorText>{allErrors.misc}</ErrorText>
          </>
        }
      >
        <TextInput
          required
          name="dive_site_name"
          label="Name"
          placeholder="Enter the dive site name"
          {...form.getInputProps("dive_site_name")}
          error={allErrors.dive_site_name}
        />

        <CountrySearch
          value={form.values.country}
          onChange={(v) => form.setFieldValue("country", v)}
          onSetCountryFields={handleSetCountryFields}
          onResetCountryFields={handleResetCountryFields}
          countryIdProps={{
            ...form.getInputProps("country_id"),
            name: "country_id",
          }}
          error={allErrors.country_id}
        />
        <LocationSearchClient
          required
          disabled={!form.values.country_code}
          filter={form.values.country_code}
          locationInputProps={{
            ...form.getInputProps("location"),
            label: "Location",
            name: "location",
            placeholder: "Enter the location",
            // autoFocus: Boolean(tempDiveSite),
          }}
          latitudeInputProps={{
            ...form.getInputProps("latitude"),
            name: "latitude",
          }}
          longitudeInputProps={{
            ...form.getInputProps("longitude"),
            name: "longitude",
          }}
          placeIdInputProps={{
            ...form.getInputProps("place_id"),
            name: "place_id",
          }}
          error={
            allErrors.location ||
            allErrors.place_id ||
            allErrors.latitude ||
            allErrors.longitude
          }
        />
      </Container>
    </Form>
  ) : (
    <Container>
      <Title
        order={2}
        size="h3"
        style={{
          textAlign: "center",
          marginBottom: 20,
        }}
      >
        You have unsaved changes. Are you sure you want to{" "}
        {showLeaveWarning === "close" ? "leave" : "go back"}?
      </Title>
      <Row>
        <Button
          variant="light"
          style={{ flex: 1 }}
          onClick={() => {
            setShowLeaveWarning(null);
          }}
          disabled={fetcher.state === "submitting"}
        >
          No thanks
        </Button>
        <Button
          variant="default"
          style={{ flex: 1 }}
          onClick={() => {
            if (showLeaveWarning === "go-back") {
              onGoBack();
            } else {
              handleClose();
            }
          }}
          loading={fetcher.state === "submitting"}
          disabled={fetcher.state === "submitting"}
        >
          Yes please
        </Button>
      </Row>
    </Container>
  );
}

export function DiveSiteModal({
  modalHistory,
  onClose,
  goBack,
  initialValues,
  modalType,
}: {
  modalHistory: ModalTypes[];
  onClose: () => void;
  goBack: (count?: number, data?: ModalData) => void;
  modalType: ModalTypes;
  initialValues: Partial<DiveSiteType>;
}) {
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [showLeaveWarning, setShowLeaveWarning] = useState<
    "close" | "go-back" | null
  >(null);

  const prevModal = modalHistory[modalHistory.length - 2];

  const handleClose = () => {
    onClose();
    setIsFormDirty(false);
    setTimeout(() => {
      setShowLeaveWarning(null);
    }, 200);
  };

  const handleCheckClose = () => {
    if (!isFormDirty) {
      handleClose();
    } else {
      setShowLeaveWarning("close");
    }
  };

  const handleGoBack = (count?: number, data?: ModalData) => {
    goBack(count, data);

    setTimeout(() => {
      setShowLeaveWarning(null);
    }, 200);
  };

  const handleCheckGoBack = (count?: number, data?: ModalData) => {
    if (!isFormDirty) {
      handleGoBack(count, data);
    } else {
      setShowLeaveWarning("go-back");
    }
  };

  return (
    <WizardModal
      modalHistory={modalHistory}
      modalType={modalType}
      onClose={handleCheckClose}
      titleIcon={<IconMapPin />}
      title={
        modalType === MODALS.DIVE_SITE_EDIT
          ? "Edit dive site"
          : "Add a dive site"
      }
      withCloseButton={!showLeaveWarning}
      isShort={Boolean(showLeaveWarning)}
    >
      <FormStuff
        initialValues={initialValues}
        prevModal={prevModal}
        onGoBack={handleGoBack}
        onCheckGoBack={handleCheckGoBack}
        handleClose={handleClose}
        showLeaveWarning={showLeaveWarning}
        setShowLeaveWarning={setShowLeaveWarning}
        setIsFormDirty={setIsFormDirty}
      />
    </WizardModal>
  );
}
