import { Textarea as MantineTextarea } from "@mantine/core";
import * as classes from "./Textarea.css";

export function Textarea(props: React.ComponentProps<typeof MantineTextarea>) {
  return (
    <MantineTextarea
      {...props}
      value={props.value ?? ""}
      classNames={{
        input: classes.input,
      }}
    />
  );
}
