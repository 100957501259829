import { ChangeEvent, useEffect, useState } from "react";
import { ClientOnly } from "remix-utils/client-only";
import usePlacesAutocomplete, { getDetails } from "use-places-autocomplete";
import { Loader, TextInput } from "@mantine/core";
import { Autocomplete } from "~/components/_common/form/Autocomplete/Autocomplete";
import { vars } from "~/config/theme";

import * as classes from "./LocationSearch.css";

type Props = {
  required?: boolean;
  disabled?: boolean;
  error?: string | null;
  hidden?: boolean;
  filter?: string | null;
  locationInputProps: React.ComponentProps<typeof Autocomplete> & {
    name: string;
    autoFocus?: boolean;
  };
  placeIdInputProps: React.ComponentProps<typeof TextInput> & { name: string };
  // Todo: Make these required
  latitudeInputProps?: React.ComponentProps<typeof TextInput> & {
    name: string;
  };
  longitudeInputProps?: React.ComponentProps<typeof TextInput> & {
    name: string;
  };
};

function LocationSearch(props: Props) {
  const [dropdownOpened, setDropdownOpened] = useState(false);
  const {
    locationInputProps,
    placeIdInputProps,
    longitudeInputProps,
    latitudeInputProps,
    filter,
    hidden,
    ...otherProps
  } = props;

  const {
    ready,
    suggestions: { data, loading, status },
    setValue,
    value,
  } = usePlacesAutocomplete({
    cacheKey: filter ?? undefined,
    requestOptions: {
      componentRestrictions: {
        country: filter ?? null,
      },
    },
  });

  const selectedPlaceId =
    data.find(({ description }) => locationInputProps.value === description)
      ?.place_id || "";

  useEffect(() => {
    const currentPlaceId = placeIdInputProps.value;

    if (selectedPlaceId && selectedPlaceId !== currentPlaceId) {
      // THis is a bit hacky. Need to keep an eye on this...
      placeIdInputProps.onChange?.(
        selectedPlaceId as unknown as ChangeEvent<HTMLInputElement>
      );

      (async () => {
        const deets = await getDetails({
          // Use the "place_id" of suggestion from the dropdown (object), here just taking the first suggestion for brevity
          placeId: selectedPlaceId,
          // Specify the return data that you want (optional)
          // https://developers.google.com/maps/documentation/places/web-service/supported_types
          fields: [
            "name",
            "geometry.location",
            "vicinity",
            "geometry.viewport",
            "type",
          ],
        });

        const {
          // @ts-expect-error - Types are wrong !?
          geometry: { location },
        } = deets;

        longitudeInputProps?.onChange?.(location.lng());
        latitudeInputProps?.onChange?.(location.lat());
      })();
    }
  }, [
    selectedPlaceId,
    placeIdInputProps,
    longitudeInputProps,
    latitudeInputProps,
  ]);

  useEffect(() => {
    setValue(locationInputProps.value ?? "");
  }, [locationInputProps.value, setValue]);

  const noResultsMessage =
    !loading && status === "ZERO_RESULTS"
      ? `No results containing "${value}"`
      : null;

  // The autocomplete component blows up if duplicate values are passed
  const uniqueData = Array.from(
    new Set(data.map(({ description }) => description))
  );

  useEffect(() => {
    if (locationInputProps.autoFocus) {
      setDropdownOpened(!selectedPlaceId);
    }
  }, [locationInputProps.autoFocus, selectedPlaceId]);

  return (
    <div className={classes.container} aria-hidden={hidden}>
      <Autocomplete
        {...locationInputProps}
        {...otherProps}
        value={locationInputProps.value}
        onChange={(v) => {
          locationInputProps.onChange?.(v);
          placeIdInputProps.onChange?.(
            null as unknown as ChangeEvent<HTMLInputElement>
          );
        }}
        disabled={!ready || props.disabled}
        data={uniqueData}
        error={noResultsMessage || props.error}
        onFocus={() => setDropdownOpened(true)}
        onBlur={() => setDropdownOpened(false)}
        dropdownOpened={dropdownOpened}
      />
      <TextInput
        {...placeIdInputProps}
        value={placeIdInputProps.value ?? ""}
        type="hidden"
        error={false}
      />
      {latitudeInputProps && (
        <TextInput
          {...latitudeInputProps}
          value={latitudeInputProps.value ?? ""}
          type="hidden"
          error={false}
        />
      )}
      {longitudeInputProps && (
        <TextInput
          {...longitudeInputProps}
          value={longitudeInputProps.value ?? ""}
          type="hidden"
          error={false}
        />
      )}
      {loading ? (
        <Loader
          className={classes.loader}
          size="xs"
          color={vars.colors.dark[4]}
        />
      ) : null}
    </div>
  );
}

export function LocationSearchClient(props: Props) {
  return <ClientOnly>{() => <LocationSearch {...props} />}</ClientOnly>;
}
