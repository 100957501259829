import dayjs from "dayjs";
import { GENDER_OPTIONS, GenderOption } from "~/constants/enums";
import { NAME_REGEX, USERNAME_REGEX } from "~/utils/auth";

export const profileFormValidationFields = {
  name: (value?: unknown) => {
    if (typeof value != "string") return "Name must be a string.";

    return !NAME_REGEX.test(value.trim())
      ? "Name must be 1-50 characters, letters and spaces only."
      : null;
  },
  username: (value?: unknown) => {
    if (!value) return "Username is required";
    if (typeof value != "string") return "Username must be a string.";

    return !USERNAME_REGEX.test(value.trim())
      ? "Username must be 3-20 characters, lowercase, alphanumeric and underscores only."
      : null;
  },
  dob: (value?: unknown) => {
    if (!value) return "Date of birth is required.";
    if (typeof value === "string" && !dayjs(value).isValid()) {
      return "Invalid date of birth.";
    }
    return null;
  },
  country_name: (value?: unknown) => {
    if (!value) return "Please select your home country.";
    return null;
  },
  country_id: () => {
    return null;
  },
  country_code: () => {
    return null;
  },
  location: (value?: unknown) => {
    if (value && typeof value !== "string") return "Location must be a string.";
    if (value && typeof value === "string" && value?.length > 50)
      return "Location must be less than 50 characters.";
    return null;
  },
  place_id: () => {
    return null;
  },
  height: (value?: unknown) => {
    if (value && typeof value === "number" && value <= 0)
      return "Height must be greater than 0.";
    return null;
  },
  weight: (value?: unknown) => {
    if (value && typeof value === "number" && value <= 0)
      return "Weight must be greater than 0.";
    return null;
  },
  gender: (value?: unknown) => {
    if (
      (value && typeof value !== "string") ||
      (value && !Object.values(GENDER_OPTIONS).includes(value as GenderOption))
    ) {
      return "Invalid gender value.";
    }

    return null;
  },
} as const;

export type ProfileFormFields = keyof typeof profileFormValidationFields;

export const profileFormValidator = (
  values: Record<string, string | number | null>
) => {
  const errors: Record<string, string> = {};

  Object.entries(values).forEach(([key, value]) => {
    const error =
      profileFormValidationFields[key as ProfileFormFields]?.(value);

    if (error) {
      errors[key] = error;
    }
  });

  return errors;
};
