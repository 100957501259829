import { useMatches } from "@remix-run/react";
import {
  IconChevronRight,
  IconMap2,
  IconCamera,
  IconScubaDiving,
  IconUserCircle,
  IconSparkles,
  IconBuildingStore,
} from "@tabler/icons-react";
import { CTAs } from "~/components/_common/CTAs/CTAs";
import { WizardModal } from "~/components/AddCreateWizard/WizardModal/WizardModal";
import { Container } from "~/components/_common/form/Container/Container";
import { type ModalTypes, MODALS } from "~/config/modals";
import { type TripLoaderResponse } from "~/types/shared";
import { showNotication } from "~/config/notifications";

const iconSize = 24;

export function AddCreateModal({
  modalHistory,
  modalType,
  setCurrentModal,
  isAuthenticated,
  currentUserId,
}: {
  modalHistory: ModalTypes[];
  modalType: ModalTypes;
  setCurrentModal: (modal: ModalTypes | null) => void;
  isAuthenticated: boolean;
  currentUserId: string;
}) {
  const { data } = (useMatches().find(({ data }) => {
    if (!data || typeof data !== "object") return false;
    return "logged_trip" in data;
  }) || {}) as { data: TripLoaderResponse };

  const trip = data && "logged_trip" in data ? data.logged_trip : null;
  const isTripAuthor =
    trip && currentUserId && trip?.author_id === currentUserId;

  const inviteToTripCta = isTripAuthor
    ? {
        onClick: () => {
          if (!isAuthenticated) {
            showNotication("You must be logged in to create a trip");
          } else {
            setCurrentModal(MODALS.INVITE_TO_TRIP);
          }
        },
        notAvailable: !isAuthenticated,
        text: "Add users to trip",
        icon: <IconChevronRight />,
        iconBefore: <IconUserCircle size={iconSize} />,
      }
    : null;

  const ctas = [
    {
      onClick: () => {
        if (!isAuthenticated) {
          showNotication("You must be logged in to log a dive");
        } else {
          setCurrentModal(MODALS.DIVE_LOG);
        }
      },
      notAvailable: !isAuthenticated,
      text: "Log dives",
      icon: <IconChevronRight />,
      iconBefore: <IconScubaDiving size={iconSize} />,
    },
    {
      onClick: () => {
        if (!isAuthenticated) {
          showNotication("You must be logged in to upload photos");
        } else {
          setCurrentModal(MODALS.UPLOAD_DIVE_PHOTOS);
        }
      },
      notAvailable: !isAuthenticated,
      text: "Upload photos",
      icon: <IconChevronRight />,
      iconBefore: <IconCamera size={iconSize} />,
    },
    {
      onClick: () => {
        if (!isAuthenticated) {
          showNotication("You must be logged in to create a trip");
        } else {
          setCurrentModal(MODALS.CREATE_TRIP);
        }
      },
      notAvailable: !isAuthenticated,
      text: "Create a trip",
      icon: <IconChevronRight />,
      iconBefore: <IconMap2 size={iconSize} />,
    },
    inviteToTripCta,
    {
      onClick: () => {
        if (!isAuthenticated) {
          showNotication("You must be logged add a dive company");
        } else {
          setCurrentModal(MODALS.CREATE_ORGANISATION);
        }
      },
      notAvailable: !isAuthenticated,
      text: "Add a company / organisation",
      icon: <IconChevronRight />,
      iconBefore: <IconBuildingStore size={iconSize} />,
    },
  ];

  return (
    <WizardModal
      modalHistory={modalHistory}
      modalType={modalType}
      onClose={() => setCurrentModal(null)}
      titleIcon={<IconSparkles />}
      title="Select an action"
      isMenu
    >
      <Container>
        <CTAs items={ctas.filter(Boolean)} />
      </Container>
    </WizardModal>
  );
}
