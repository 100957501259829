import { Switch as MantineSwitch } from "@mantine/core";
import * as classes from "./Switch.css";

export function Switch(props: React.ComponentProps<typeof MantineSwitch>) {
  return (
    <MantineSwitch
      classNames={{
        track: classes.track,
        input: classes.input,
      }}
      {...props}
    />
  );
}
