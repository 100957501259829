import { Link } from "@remix-run/react";
import { VisuallyHidden } from "@mantine/core";
import {
  IconBrandInstagram,
  IconBrandTiktok,
  IconBrandX,
  IconBrandYoutube,
} from "@tabler/icons-react";
import { Paper } from "~/components/_common/Paper/Paper";
import { ScubaNetworkRight } from "~/components/_common/logos/ScubaNetworkRight";
import { PrefetchTypes } from "~/types/shared";
import * as classes from "./Footer.css";

export function Footer({ prefetchType }: { prefetchType: PrefetchTypes }) {
  return (
    <Paper>
      <footer className={classes.footer}>
        <div>
          <h4 className={classes.heading}>Resources</h4>
          <ul className={classes.resources}>
            <li>
              <Link
                to="/privacy-policy"
                className={classes.resourceLink}
                prefetch={prefetchType}
              >
                Privacy policy
              </Link>
            </li>
            <li>
              <Link
                to="/terms-and-conditions"
                className={classes.resourceLink}
                prefetch={prefetchType}
              >
                Terms & conditions
              </Link>
            </li>
          </ul>
        </div>
        <div className={classes.logos}>
          <ScubaNetworkRight title="Scuba Network" />
          <div className={classes.socials}>
            <Link
              to="https://www.instagram.com/scuba.network"
              className={classes.socialLink}
              target="_blank"
              rel="noreferrer"
            >
              <IconBrandInstagram />
              <VisuallyHidden>Instagram</VisuallyHidden>
            </Link>
            <Link
              to="https://twitter.com/scubadotnetwork"
              className={classes.socialLink}
              target="_blank"
              rel="noreferrer"
            >
              <IconBrandX />
              <VisuallyHidden>X / Twitter</VisuallyHidden>
            </Link>
            <Link
              to="https://www.tiktok.com/@scuba.network"
              className={classes.socialLink}
              target="_blank"
              rel="noreferrer"
            >
              <IconBrandTiktok />
              <VisuallyHidden>Tiktok</VisuallyHidden>
            </Link>
            <Link
              to="https://www.youtube.com/channel/UCngn8O0cRiEStZYrGCA7X9g"
              className={classes.socialLink}
              target="_blank"
              rel="noreferrer"
            >
              <IconBrandYoutube />
              <VisuallyHidden>Youtube</VisuallyHidden>
            </Link>
          </div>
        </div>
      </footer>
    </Paper>
  );
}
