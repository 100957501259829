import { Link } from "@remix-run/react";
import classNames from "classnames";
import * as classes from "./LinkIcon.css";

export function LinkIcon({
  to,
  label,
  className = "",
  children,
  secondary,
  onClick = () => {},
}: {
  to: string;
  label?: string;
  className?: string;
  secondary?: boolean;
  children: React.ReactNode;
  onClick?: () => void;
}) {
  return (
    <Link
      className={classNames(classes.linkIcon, className, {
        [classes.secondary]: secondary,
      })}
      to={to}
      onClick={onClick}
      aria-label={label}
    >
      {children}
    </Link>
  );
}
