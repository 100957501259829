import { Suspense, lazy, memo, useEffect, useState } from "react";
import { IconArrowRight, IconCamera } from "@tabler/icons-react";
import { useForm } from "@mantine/form";
import { Loader, Text, Title } from "@mantine/core";
import Cookies from "js-cookie";
import { useNavigate, useParams, useRevalidator } from "@remix-run/react";
import { WizardModal } from "~/components/AddCreateWizard/WizardModal/WizardModal";
import { MODALS, ModalTypes } from "~/config/modals";
import { Button } from "~/components/_common/Button/Button";
import { Row } from "~/components/_common/Row/Row";
import { type OutletContext, Photo, PartialLoggedTrip } from "~/types/shared";
import { PROFILE, PROFILE_PHOTOS, TRIP } from "~/config/routes";
import { Container } from "~/components/_common/form/Container/Container";
import { DiveLogSelect } from "~/components/_common/DiveLogSelect/DiveLogSelect";
import { TripSelect } from "~/components/_common/TripSelect/TripSelect";
import { DIVE_LOG_MODAL_TAB } from "~/config/cookie-keys";
import { GALLERY } from "~/components/modals/DiveLogModal/DiveLogModal";
import * as classes from "./UploadPhotosModal.css";

const ImageUploadDashboard = lazy(async () => {
  const module = await import(
    "~/components/_common/ImageUploadDashboard/ImageUploadDashboard"
  );

  return { default: module.default };
});
const MemoizedImageUploadDashboard = memo(ImageUploadDashboard);

function FormStuff({
  currentModal,
  setCurrentModal,
  showLeaveWarning,
  setShowLeaveWarning,
  onClose,
  setIsFormDirty,
  outletContext,
  loggedTrips,
  setSelectedTripId,
  newDiveLogId,
  newTripId,
  currentUserId,
}: {
  currentModal: ModalTypes | null;
  setCurrentModal: (modal: ModalTypes) => void;
  showLeaveWarning: "close" | "go-back" | null;
  setShowLeaveWarning: (value: "close" | "go-back" | null) => void;
  onClose: () => void;
  setIsFormDirty: (value: boolean) => void;
  outletContext: OutletContext;
  loggedTrips: PartialLoggedTrip[];
  setSelectedTripId: (tripId?: string) => void;
  newDiveLogId?: string;
  newTripId?: string;
  currentUserId: string;
}) {
  const { uppy, goToPreviousModal, isUploadingFiles, uploadComplete } =
    outletContext;
  const navigate = useNavigate();
  const params = useParams();

  const [fetcherState, setFetcherState] = useState<
    "idle" | "loading" | "submitting"
  >("idle");

  const showLoadingState =
    fetcherState !== "idle" && currentModal === MODALS.UPLOAD_DIVE_PHOTOS;

  const form = useForm<{
    log_id: string | undefined;
    trip_id: string | undefined;
    temp_trip_id?: string;
    dive_site_id?: string;
  }>({
    initialValues: {
      log_id: params.logId,
      trip_id: params.tripId,
      temp_trip_id: params.tripId ?? "",
      dive_site_id: "",
    },
  });

  const diveSiteId = form.values.dive_site_id;
  const diveLogId = form.values.log_id;
  const tripId = form.values.trip_id;

  const isFormDirty = form.isDirty() || isUploadingFiles;

  const showPhotoUploader = (tripId || diveLogId) && !showLoadingState;
  const { setFieldValue } = form;

  useEffect(() => {
    if (tripId) {
      setFieldValue("log_id", undefined);
    }

    setSelectedTripId(tripId);
  }, [tripId, setFieldValue, setSelectedTripId]);

  useEffect(() => {
    if (newDiveLogId) {
      setFieldValue("log_id", newDiveLogId);
    }
  }, [newDiveLogId, setFieldValue]);

  useEffect(() => {
    if (newTripId) {
      setFieldValue("trip_id", newTripId);
    }
  }, [newTripId, setFieldValue]);

  useEffect(() => {
    setIsFormDirty(isFormDirty);
  }, [setIsFormDirty, isFormDirty]);

  useEffect(() => {
    if (diveLogId) {
      uppy?.cancelAll();
    }
  }, [diveLogId, uppy]);

  return !showLeaveWarning ? (
    <Container
      footer={
        <Row flexEnd>
          <Button
            variant="transparent"
            mr="auto"
            disabled={fetcherState === "submitting"}
            onClick={() => {
              if (isFormDirty) {
                setShowLeaveWarning("go-back");
              } else {
                goToPreviousModal();
              }
            }}
          >
            Go back
          </Button>

          <Button
            variant="default"
            disabled={!uploadComplete}
            onClick={() => {
              // Set the gallery tab for the dive log modal
              if (diveSiteId) {
                Cookies.set(DIVE_LOG_MODAL_TAB, GALLERY);
              }

              const tripId = form.values.trip_id || form.values.temp_trip_id;

              // if on a trip page or selected a trip photo, stay on trip page
              if (params.tripId || tripId) {
                const nextPath =
                  diveLogId && diveSiteId
                    ? `${TRIP}/${tripId}/dive-site/${diveSiteId}/${diveLogId}`
                    : `${TRIP}/${tripId}`;

                navigate(nextPath);
              } else {
                const nextPath =
                  diveLogId && diveSiteId
                    ? `${PROFILE}/${currentUserId}/dive-site/${diveSiteId}/${diveLogId}`
                    : `${PROFILE_PHOTOS}/${currentUserId}`;
                navigate(nextPath);
              }

              onClose();
            }}
          >
            <Text size="md" mr="md">
              View photos
            </Text>

            <IconArrowRight />
          </Button>
        </Row>
      }
    >
      <div className={classes.container}>
        <Text
          style={{
            textAlign: "center",
            opacity: 0.6,
          }}
          size="sm"
        >
          Please select a trip and / or a dive log to connect these photos to.
          For non-dive photos select a trip only.
        </Text>
        <TripSelect
          form={form}
          trips={loggedTrips}
          selectProps={form.getInputProps("trip_id")}
        />
        <DiveLogSelect
          form={form}
          setCurrentModal={setCurrentModal}
          setFetcherState={setFetcherState}
          initialDiveLogId={newDiveLogId}
        />
        <Suspense fallback={<Loader type="bars" mx="auto" my="auto" />}>
          <MemoizedImageUploadDashboard
            diveLogId={diveLogId}
            tripId={tripId || form.values.temp_trip_id}
            diveSiteId={diveSiteId}
            outletContext={outletContext}
            hidden={!showPhotoUploader}
          />
        </Suspense>
      </div>
    </Container>
  ) : (
    <Container>
      <Title
        order={2}
        size="h3"
        style={{
          textAlign: "center",
          marginBottom: 20,
        }}
      >
        You have unsaved changes. Are you sure you want to leave?
      </Title>
      <Row>
        <Button
          variant="light"
          style={{ flex: 1 }}
          onClick={() => {
            setShowLeaveWarning(null);
          }}
          disabled={fetcherState === "submitting"}
        >
          No thanks
        </Button>
        <Button
          variant="default"
          style={{ flex: 1 }}
          onClick={() => {
            if (showLeaveWarning === "go-back") {
              goToPreviousModal();
            } else {
              onClose();
            }

            setShowLeaveWarning(null);
          }}
          loading={fetcherState === "submitting"}
          disabled={fetcherState === "submitting"}
        >
          Yes please
        </Button>
      </Row>
    </Container>
  );
}

export function UploadPhotosModal({
  modalHistory,
  modalType,
  onClose,
  setCurrentModal,
  currentModal,
  outletContext,
  loggedTrips,
  setSelectedTripId,
  newTripId,
  newDiveLogId,
  currentUserId,
}: {
  modalHistory: ModalTypes[];
  modalType: typeof MODALS.UPLOAD_DIVE_PHOTOS;
  onClose: () => void;
  setCurrentModal: (modal: ModalTypes) => void;
  currentModal: ModalTypes | null;
  photos?: Partial<Photo>[];
  outletContext: OutletContext;
  loggedTrips: PartialLoggedTrip[];
  setSelectedTripId: (tripId?: string) => void;
  newDiveLogId?: string;
  newTripId?: string;
  currentUserId: string;
}) {
  const { revalidate } = useRevalidator();
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [showLeaveWarning, setShowLeaveWarning] = useState<
    "close" | "go-back" | null
  >(null);

  const { uploadComplete, setUploadComplete } = outletContext;

  const handleClose = () => {
    if (uploadComplete) {
      revalidate();
      setUploadComplete(false);
    }
    onClose();
  };

  return (
    <WizardModal
      modalHistory={modalHistory}
      modalType={modalType}
      onClose={() => {
        if (isFormDirty) {
          setShowLeaveWarning("close");
        } else {
          handleClose();
          setShowLeaveWarning(null);
        }
      }}
      titleIcon={<IconCamera />}
      title={"Photos"}
      withCloseButton={!showLeaveWarning}
      isShort={Boolean(showLeaveWarning)}
    >
      <FormStuff
        onClose={handleClose}
        currentModal={currentModal}
        showLeaveWarning={showLeaveWarning}
        setShowLeaveWarning={setShowLeaveWarning}
        setCurrentModal={setCurrentModal}
        setIsFormDirty={setIsFormDirty}
        outletContext={outletContext}
        loggedTrips={loggedTrips}
        setSelectedTripId={setSelectedTripId}
        newDiveLogId={newDiveLogId}
        newTripId={newTripId}
        currentUserId={currentUserId}
      />
    </WizardModal>
  );
}
