import { DB } from "~/types/shared";

export const TRIP_TYPES = {
  LIVEABOARD: "liveaboard",
  RESORT: "resort",
  DAY_TRIP: "day-trip",
  OTHER: "other",
} as const;

export const PHOTOS_FILTER_KEY = "photos_filter" as const;

export type TripType = (typeof TRIP_TYPES)[keyof typeof TRIP_TYPES];

export type TripFormInputs = {
  countries: {
    country: string | null;
  };
  country_id: string | null;
  country_code: string | null;
  location: string;
  place_id: string | null;
  latitude: string | number | null;
  longitude: string | number | null;
  trip_type: TripType;
  start_date: string | null;
  end_date?: string | null;
  company_name?: string | null;
  company_id?: string | null;
  is_public: boolean | null;
};

export type TripErrors = {
  [K in keyof TripFormInputs]?: string | null;
} & {
  misc?: string;
};

export const tripFormValidator = (values: Partial<TripFormInputs>) => {
  const tripType = values.trip_type;

  const validators = {
    country_id: !values.country_id ? "Please select a country" : null,
    location: !values.country_id
      ? "Please select a country first"
      : !values.location
      ? "Please select a location"
      : null,
    place_id: !values.place_id
      ? "Please select a location from the list"
      : null,
    latitude: !values.latitude
      ? "Please select a location from the list"
      : null,
    longitude: !values.longitude
      ? "Please select a location from the list"
      : null,
    trip_type: !values.trip_type ? "Please select a trip type" : null,
  };

  if (tripType === TRIP_TYPES.LIVEABOARD) {
    return {
      ...validators,
      company_id: !values.company_id
        ? "Please select a liveaboard from the list"
        : null,
      start_date: !values.start_date ? "Please select the start date" : null,
      end_date: !values.end_date ? "Please select the end date" : null,
    };
  }

  if (tripType === TRIP_TYPES.RESORT) {
    return {
      ...validators,
      start_date: !values.start_date ? "Please select the start date" : null,
      end_date: !values.end_date ? "Please select the end date" : null,
      company_id: !values.company_id
        ? "Please select a resort from the list"
        : null,
    };
  }

  if (tripType === TRIP_TYPES.DAY_TRIP) {
    return {
      ...validators,
      start_date: !values.start_date ? "Please enter the date" : null,
    };
  }

  if (tripType === TRIP_TYPES.OTHER) {
    return {
      ...validators,
      start_date: !values.start_date ? "Please select the start date" : null,
      end_date: !values.end_date ? "Please select the end date" : null,
    };
  }

  return validators;
};

export const getTripHeading = (
  trip: DB["logged_trips"]["Row"] & {
    dive_companies: { company_name: string } | null;
  }
) => {
  const { trip_type, dive_companies } = trip;
  if (
    (trip_type === TRIP_TYPES.LIVEABOARD || trip_type === TRIP_TYPES.RESORT) &&
    dive_companies?.company_name
  ) {
    return dive_companies.company_name;
  }

  if (trip.trip_type === TRIP_TYPES.DAY_TRIP) {
    return trip.location;
  }

  return trip.location;
};
