import { useEffect } from "react";
import {
  AutocompleteProps,
  Group,
  Loader,
  Text,
  TextInput,
} from "@mantine/core";
import { useFetcher } from "@remix-run/react";
import { Autocomplete } from "~/components/_common/form/Autocomplete/Autocomplete";
import { loader } from "~/routes/_api.get-countries";
import { GET_COUNTRIES } from "~/config/api";

import * as classes from "./CountrySearch.css";

export function CountrySearch({
  onSetCountryFields,
  onResetCountryFields,
  value,
  onChange,
  countryIdProps,
  error,
}: {
  onSetCountryFields: (countryId: string, countryCode: string) => void;
  onResetCountryFields: () => void;
  value?: string | null;
  onChange: (value: string) => void;
  countryIdProps: React.ComponentProps<typeof TextInput>;
  error?: string | null;
}) {
  const { load, data, state } = useFetcher<typeof loader>({
    key: `get-country-${value}`,
  });

  const { results, error: searchError } = data || {};

  const renderAutocompleteOption: AutocompleteProps["renderOption"] = ({
    option,
  }) => {
    const { label } = option as { label: string; value: string };

    return (
      <Group className={classes.itemContainer}>
        <div className={classes.optionText}>
          <Text>{label}</Text>
        </div>
      </Group>
    );
  };

  const ids = results?.map(({ country, id }) => ({
    value: id,
    label: country,
  }));

  useEffect(() => {
    if (value && value.length > 1) {
      load(`${GET_COUNTRIES}?search=${value}`);
    } else if (countryIdProps.value) {
      onResetCountryFields();
    }
  }, [load, value, onResetCountryFields, countryIdProps.value]);

  useEffect(() => {
    if (value && results?.length) {
      const selectedItem = results?.find(({ country }) => country === value);
      if (selectedItem) {
        onSetCountryFields(selectedItem.id, selectedItem.alpha_2);
      }
    }
  }, [value, results, onSetCountryFields]);

  const noResultsMessage =
    state === "idle" && results?.length === 0
      ? `No results containing "${value}"`
      : null;

  return (
    <div className={classes.container}>
      <Autocomplete
        required
        data={ids}
        renderOption={renderAutocompleteOption}
        maxDropdownHeight={300}
        label="Country"
        placeholder="Search countries..."
        value={value ?? ""}
        onChange={onChange}
        error={
          state === "submitting"
            ? false
            : searchError || noResultsMessage || error
        }
      />
      <TextInput
        {...countryIdProps}
        value={countryIdProps.value ?? ""}
        type="hidden"
        error={false}
      />
      {state !== "idle" ? (
        <Loader className={classes.loader} size="xs" />
      ) : null}
    </div>
  );
}
