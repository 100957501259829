import { useFetcher } from "@remix-run/react";
import { useCallback, useMemo } from "react";
import { action } from "~/routes/_api.update-preferences";
import { type Database } from "~/types/supabase";
import { type UserProfile } from "~/types/shared";

type Enums = Database["public"]["Enums"];

/** Only to be imported from the root.tsx file */
export function useUpdatePreferences({
  initialProfile,
}: {
  initialProfile?: UserProfile;
}) {
  // FYI, preferences will come from the cookie when the user is not authenticated
  const {
    preferred_temperature_unit,
    preferred_depth_unit,
    preferred_pressure_unit,
    preferred_weight_unit,
    preferred_volume_unit,
  } = initialProfile ?? {};
  const fetcher = useFetcher<typeof action>();
  const { submit } = fetcher;

  const optimisticUserProfile = useMemo(
    () => ({
      ...initialProfile,
      preferred_temperature_unit: (fetcher.formData?.get(
        "preferred_temperature_unit"
      ) ?? preferred_temperature_unit) as Enums["temperature_units"],
      preferred_depth_unit: (fetcher.formData?.get("preferred_depth_unit") ??
        preferred_depth_unit) as Enums["distance_units"],
      preferred_pressure_unit: (fetcher.formData?.get(
        "preferred_pressure_unit"
      ) ?? preferred_pressure_unit) as Enums["pressure_units"],
      preferred_weight_unit: (fetcher.formData?.get("preferred_weight_unit") ??
        preferred_weight_unit) as Enums["weight_units"],
      preferred_volume_unit: (fetcher.formData?.get("preferred_volume_unit") ??
        preferred_volume_unit) as Enums["volume_units"],
    }),
    [
      initialProfile,
      fetcher.formData,
      preferred_depth_unit,
      preferred_pressure_unit,
      preferred_temperature_unit,
      preferred_volume_unit,
      preferred_weight_unit,
    ]
  );

  const submitValue = useCallback(
    (pref: Partial<UserProfile>) => {
      submit(
        // @ts-expect-error ???
        {
          // Pass previous values in as well incase user submits twice, causing previous submission to be cancelled
          ...optimisticUserProfile,
          ...pref,
        },
        {
          method: "post",
          action: "/update-preferences",
        }
      );
    },
    [submit, optimisticUserProfile]
  );

  const handleToggleTempUnit = useCallback(() => {
    submitValue({
      preferred_temperature_unit:
        optimisticUserProfile.preferred_temperature_unit === "celsius"
          ? "fahrenheit"
          : "celsius",
    });
  }, [optimisticUserProfile, submitValue]);

  const handleToggleDepthUnit = useCallback(() => {
    submitValue({
      preferred_depth_unit:
        optimisticUserProfile.preferred_depth_unit === "metres"
          ? "feet"
          : "metres",
    });
  }, [optimisticUserProfile, submitValue]);

  const handleToggleWeightUnit = useCallback(() => {
    submitValue({
      preferred_weight_unit:
        optimisticUserProfile.preferred_weight_unit === "kg" ? "pounds" : "kg",
    });
  }, [optimisticUserProfile, submitValue]);

  const handleTogglePressureUnit = useCallback(() => {
    submitValue({
      preferred_pressure_unit:
        optimisticUserProfile.preferred_pressure_unit === "bar" ? "psi" : "bar",
    });
  }, [optimisticUserProfile, submitValue]);

  const handleToggleVolumeUnit = useCallback(() => {
    submitValue({
      preferred_volume_unit:
        optimisticUserProfile.preferred_volume_unit === "litres"
          ? "cubic-feet"
          : "litres",
    });
  }, [optimisticUserProfile, submitValue]);

  const syncPreferences = useCallback(() => {
    submitValue({});
  }, [submitValue]);

  return {
    handleToggleTempUnit,
    handleToggleDepthUnit,
    handleToggleWeightUnit,
    handleTogglePressureUnit,
    handleToggleVolumeUnit,
    optimisticUserProfile,
    syncPreferences,
  };
}
