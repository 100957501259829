import dayjs from "dayjs";
import { type DiveSample, SAMPLE_RATE } from "~/config/dive-log";

export const suuntoFormatter = (data: {
  Dive: {
    StartTime: string;
    Duration: number;
    MaxDepth: number;
    DiveSamples: {
      "Dive.Sample": Array<{
        Depth: number;
        Temperature: number;
        Time: number;
      }>;
    };
  };
}) => {
  const {
    Dive: { StartTime, Duration, MaxDepth, DiveSamples },
  } = data;

  let min_water_temp = Infinity;
  let max_water_temp = -Infinity;
  const dive_samples: DiveSample[] = [];

  const startTime = new Date(StartTime);

  DiveSamples["Dive.Sample"].forEach(({ Depth, Temperature, Time }) => {
    const lastSample = dive_samples[dive_samples.length - 1];

    const timeDiff = lastSample?.elapsed_time
      ? Time - lastSample.elapsed_time
      : Infinity;

    const timestamp = new Date(startTime.getTime() + Time * 1000);

    if (timeDiff >= SAMPLE_RATE) {
      dive_samples.push({
        depth: Depth,
        water_temp: Temperature,
        timestamp,
        time: dayjs(timestamp).format("HH:mm:ss"),
        elapsed_time: Time,
        // heart_rate,
      });
    }

    if (Temperature < min_water_temp) {
      min_water_temp = Temperature;
    }

    if (Temperature > max_water_temp) {
      max_water_temp = Temperature;
    }
  });

  const entry_date = dayjs(startTime).format("YYYY-MM-DD");
  const entry_time = dayjs(startTime).format("HH:mm");

  return {
    dive_samples,
    entry_date,
    entry_time,
    dive_duration: Math.round(Duration / 60),
    min_water_temp: Math.round(min_water_temp),
    max_water_temp: Math.round(max_water_temp),
    max_depth: Math.round(MaxDepth),
    // water_type: water_type as WaterType,
  };
};
