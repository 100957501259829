import dayjs from "dayjs";

export function getDateAndTime({
  date,
  time,
}: {
  date: string | number | Date;
  time?: string | null;
}) {
  const timeParts = time?.split(":");
  const hours = timeParts?.[0];
  const minutes = timeParts?.[1];
  const formattedTime = timeParts ? ` - ${hours}:${minutes}` : "";
  return `${dayjs(date).format("DD MMM YYYY")} ${formattedTime}`;
}
