import { Fragment } from "react";
import { Link, useLocation, useParams } from "@remix-run/react";
import { SupabaseClient } from "@supabase/supabase-js";
import {
  IconLogin,
  IconLogout,
  IconMenu2,
  IconMoon,
  IconPlus,
  IconRuler3,
  IconSun,
} from "@tabler/icons-react";
import { UserAvatar } from "~/components/_common/UserAvatar/UserAvatar";
import { ScubaNetwork } from "~/components/_common/logos/ScubaNetwork";
import { LinkIcon } from "~/components/_common/LinkIcon/LinkIcon";
import { ButtonIcon } from "~/components/_common/ButtonIcon/ButtonIcon";
import { AUTH_PAGES, EXPLORE, PROFILE, SIGN_IN } from "~/config/routes";
import { Menu, MenuItem } from "~/components/_common/Menu/Menu";
import { MenuDivider, useMantineColorScheme } from "@mantine/core";
import { MODALS, type ModalTypes } from "~/config/modals";
import { UserProfile } from "~/types/shared";
import * as classes from "./AppHeader.css";

function AppHeaderContent({
  imageBasePath,
  supabase,
  onCreateModalOpen,
  hasError,
  userProfile,
  user,
  setCurrentModal,
  isOnAuthPage,
}: {
  imageBasePath?: string;
  supabase?: SupabaseClient;
  user?: {
    id: string;
  };
  onCreateModalOpen: () => void;
  hasError?: boolean;
  userProfile?: UserProfile;
  setCurrentModal?: (modal: ModalTypes | null) => void;
  isOnAuthPage: boolean;
}) {
  const { pathname } = useLocation();
  const { toggleColorScheme, colorScheme } = useMantineColorScheme();
  const { photoId } = useParams();

  const menuItems = [
    <MenuItem
      key="light-dark-mode"
      onClick={() => toggleColorScheme()}
      leftSection={colorScheme === "dark" ? <IconSun /> : <IconMoon />}
      closeMenuOnClick={false}
    >
      {colorScheme === "dark" ? "Light mode" : "Dark mode"}
    </MenuItem>,
    setCurrentModal ? (
      <MenuItem
        key="units"
        onClick={() => setCurrentModal(MODALS.PREFERENCES)}
        leftSection={<IconRuler3 />}
      >
        Unit settings
      </MenuItem>
    ) : null,
    user ? (
      <MenuItem
        key="sign-out"
        onClick={async () => {
          if (!supabase) return;

          const { error } = await supabase.auth.signOut();

          if (error) {
            console.error("Sign out error", error);
          }
        }}
        leftSection={<IconLogin />}
      >
        Sign out
      </MenuItem>
    ) : (
      <MenuItem
        key="sign-in"
        component={Link}
        to={`${SIGN_IN}?redirect=${pathname}`}
        leftSection={<IconLogout />}
      >
        Sign in
      </MenuItem>
    ),
  ];

  if (imageBasePath && user) {
    menuItems.unshift(
      <Fragment key="profile-link">
        <MenuItem
          key="profile"
          component={Link}
          to={`${PROFILE}/${user.id}`}
          leftSection={
            <UserAvatar
              size={24}
              label="Profile"
              imageUrl={userProfile?.avatar?.small_url}
            />
          }
        >
          Profile
        </MenuItem>
        <MenuDivider />
      </Fragment>
    );
  }

  return (
    <>
      <div className={classes.ctas}>
        {!isOnAuthPage && (
          <ButtonIcon
            onClick={onCreateModalOpen}
            visuallyHidden={!!photoId}
            className={classes.createButton}
            filled
          >
            <IconPlus />
          </ButtonIcon>
        )}

        {!isOnAuthPage ? (
          <>
            <Menu
              position="bottom-end"
              button={
                <ButtonIcon
                  disabled={hasError}
                  visuallyHidden={!!photoId}
                  className={classes.cta}
                  filled
                >
                  <IconMenu2 />
                </ButtonIcon>
              }
              items={menuItems}
            />
          </>
        ) : null}
      </div>
    </>
  );
}

export function AppHeader(props: {
  imageBasePath?: string;
  supabase?: SupabaseClient;
  user?: {
    id: string;
  };
  onCreateModalOpen: () => void;
  hasError?: boolean;
  userProfile?: UserProfile;
  setCurrentModal?: (modal: ModalTypes | null) => void;
}) {
  const { pathname } = useLocation();
  const isOnAuthPage = AUTH_PAGES.includes(pathname);

  return (
    <header className={classes.header}>
      <LinkIcon to={EXPLORE}>
        <ScubaNetwork
          title="Scuba Network"
          titleId="scubaLogo"
          className={classes.appLogo}
        />
      </LinkIcon>
      {!props.hasError && (
        <AppHeaderContent {...props} isOnAuthPage={isOnAuthPage} />
      )}
    </header>
  );
}
