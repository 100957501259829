import { Title } from "@mantine/core";
import { SegmentedControl } from "~/components/_common/form/SegmentControl/SegmentControl";
import { OutletContext } from "~/types/shared";
import { distance, temperature, volume, weights } from "~/config/abbreviations";
import * as classes from "./UnitToggles.css";

export function UnitToggles({
  outletContext,
}: {
  outletContext: OutletContext;
}) {
  const {
    handleToggleTempUnit,
    handleToggleDepthUnit,
    handleToggleWeightUnit,
    handleTogglePressureUnit,
    handleToggleVolumeUnit,
    userProfile,
  } = outletContext;

  const {
    preferred_temperature_unit,
    preferred_depth_unit,
    preferred_pressure_unit,
    preferred_volume_unit,
    preferred_weight_unit,
  } = userProfile;

  return (
    <div className={classes.container}>
      <div>
        <Title order={3} className={classes.label}>
          Temperature
        </Title>
        <SegmentedControl
          data={[
            { value: "celsius", label: `Celsius (${temperature.celsius})` },
            {
              value: "fahrenheit",
              label: `Fahrenheit (${temperature.fahrenheit})`,
            },
          ]}
          value={preferred_temperature_unit}
          onClick={handleToggleTempUnit}
        />
      </div>
      <div>
        <Title order={3} className={classes.label}>
          Length
        </Title>
        <SegmentedControl
          data={[
            { value: "metres", label: `Metres (${distance.metres})` },
            { value: "feet", label: `Feet (${distance.feet})` },
          ]}
          value={preferred_depth_unit}
          onClick={handleToggleDepthUnit}
        />
      </div>
      <div>
        <Title order={3} className={classes.label}>
          Weight
        </Title>
        <SegmentedControl
          data={[
            { value: "kg", label: `Kilograms (${weights.kg})` },
            { value: "pounds", label: `Pounds (${weights.pounds})` },
          ]}
          value={preferred_weight_unit}
          onClick={handleToggleWeightUnit}
        />
      </div>
      <div>
        <Title order={3} className={classes.label}>
          Pressure
        </Title>
        <SegmentedControl
          data={[
            { value: "psi", label: "PSI" },
            { value: "bar", label: "Bar" },
          ]}
          value={preferred_pressure_unit}
          onClick={handleTogglePressureUnit}
        />
      </div>
      <div>
        <Title order={3} className={classes.label}>
          Volume
        </Title>
        <SegmentedControl
          data={[
            { value: "litres", label: `Litres (${volume.litres})` },
            {
              value: "cubic-feet",
              label: `Cubic feet (${volume["cubic-feet"]})`,
            },
          ]}
          value={preferred_volume_unit}
          onClick={handleToggleVolumeUnit}
        />
      </div>
    </div>
  );
}
