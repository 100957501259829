import { Title } from "@mantine/core";
import { UseFormReturnType, useForm } from "@mantine/form";
import {
  useFetcher,
  useNavigate,
  useParams,
  useRevalidator,
} from "@remix-run/react";
import { IconArrowLeft, IconUserCircle } from "@tabler/icons-react";
import { FormEvent, useCallback, useEffect, useRef, useState } from "react";
import { WizardModal } from "~/components/AddCreateWizard/WizardModal/WizardModal";
import { CountrySearch } from "~/components/CountrySearch/CountrySearch";
import { LocationSearchClient } from "~/components/LocationSearch/LocationSearch";
import { Button } from "~/components/_common/Button/Button";
import { Popover } from "~/components/_common/Popover/Popover";
import { Row } from "~/components/_common/Row/Row";
import { Container } from "~/components/_common/form/Container/Container";
import { DateInput } from "~/components/_common/form/DateInput/DateInput";
import { ErrorText } from "~/components/_common/form/ErrorText/ErrorText";
import { Form } from "~/components/_common/form/Form/Form";
import { NumberInput } from "~/components/_common/form/NumberInput/NumberInput";
import { SegmentedControl } from "~/components/_common/form/SegmentControl/SegmentControl";
import { TextInput } from "~/components/_common/form/TextInput/TextInput";
import { distance, weights } from "~/config/abbreviations";
import { MODALS, ModalTypes } from "~/config/modals";
import { showNotication } from "~/config/notifications";
import {
  ProfileFormFields,
  profileFormValidationFields,
} from "~/config/profile";
import { PROFILE } from "~/config/routes";
import type { DistanceUnit, WeightUnit } from "~/constants/enums";
import { action } from "~/routes/_api.upsert-profile";
import type { UserProfile } from "~/types/shared";
import {
  kilosToPounds,
  metresToFeet,
  toTwoDecimalPlaces,
} from "~/utils/conversions";
import { getDateOnly } from "~/utils/getDateOnly";

const HeightFields = ({
  preferred_depth_unit,
  error,
  form,
}: {
  preferred_depth_unit: DistanceUnit;
  error?: string;
  form: UseFormReturnType<Partial<UserProfile>>;
}) => {
  if (preferred_depth_unit === "metres") {
    return (
      <NumberInput
        label={`Height`}
        rightSection={distance.metres}
        name="height"
        style={{
          flex: 1,
        }}
        setFieldValue={form.setFieldValue}
        {...form.getInputProps("height")}
        error={error}
      />
    );
  }

  return (
    <Row grow>
      <NumberInput
        isInteger
        label="Height"
        rightSection={distance.feet}
        name="feet"
        {...form.getInputProps("feet")}
        setFieldValue={form.setFieldValue}
        error={error}
      />
      <NumberInput
        isInteger
        label="&nbsp;"
        rightSection="in"
        name="inches"
        setFieldValue={form.setFieldValue}
        max={11}
        {...form.getInputProps("inches")}
        error={error}
      />
    </Row>
  );
};

const WeightFields = ({
  preferred_weight_unit,
  error,
  form,
}: {
  preferred_weight_unit: WeightUnit;
  error?: string;
  form: UseFormReturnType<Partial<UserProfile>>;
}) => {
  if (preferred_weight_unit === "kg") {
    return (
      <NumberInput
        label="Weight"
        rightSection={weights.kg}
        name="weight"
        placeholder="Enter your weight"
        style={{
          flex: 1,
        }}
        setFieldValue={form.setFieldValue}
        {...form.getInputProps("weight")}
        error={error}
      />
    );
  }

  return (
    <Row grow>
      <NumberInput
        isInteger
        label="Weight"
        rightSection="st"
        name="stone"
        {...form.getInputProps("stone")}
        setFieldValue={form.setFieldValue}
        error={error}
      />
      <NumberInput
        isInteger
        label="&nbsp;"
        rightSection={weights.pounds}
        name="pounds"
        setFieldValue={form.setFieldValue}
        max={13}
        {...form.getInputProps("pounds")}
        error={error}
      />
    </Row>
  );
};

function ProfileModalForm({
  showLeaveWarning,
  setShowLeaveWarning,
  setIsFormDirty,
  onClose,
  userProfile,
  setCurrentModal,
  isOnboarding,
}: {
  showLeaveWarning: "close" | null;
  setShowLeaveWarning: (value: "close" | null) => void;
  setIsFormDirty: (value: boolean) => void;
  onClose: () => void;
  userProfile: UserProfile;
  setCurrentModal: (modal: ModalTypes | null) => void;
  isOnboarding: boolean;
}) {
  const { profileId } = useParams();
  const { revalidate } = useRevalidator();
  const navigate = useNavigate();
  const fetcher = useFetcher<typeof action>();
  const formRef = useRef<HTMLFormElement | null>(null);

  const { preferred_weight_unit, preferred_depth_unit } = userProfile || {};

  const convertedHeight =
    typeof userProfile.height === "number"
      ? preferred_depth_unit === "metres"
        ? toTwoDecimalPlaces(userProfile.height)
        : metresToFeet(userProfile.height)
      : undefined;

  const convertedWeight =
    typeof userProfile.weight === "number"
      ? preferred_weight_unit === "kg"
        ? userProfile.weight
        : kilosToPounds(userProfile.weight) / 14
      : undefined;

  const form = useForm<
    Partial<
      UserProfile & {
        feet?: string;
        inches?: string;
        stone?: string;
        pounds?: string;
      }
    >
  >({
    initialValues: {
      ...userProfile,
      height: convertedHeight,
      weight: convertedWeight,
      name: userProfile?.name ?? "",
      username: userProfile?.username ?? "",
      feet:
        typeof convertedHeight === "number"
          ? Math.floor(convertedHeight).toString()
          : "",
      inches:
        typeof convertedHeight === "number"
          ? Math.round((convertedHeight % 1) * 12).toString()
          : "",
      stone:
        typeof convertedWeight === "number"
          ? Math.floor(convertedWeight).toString()
          : "",
      pounds:
        typeof convertedWeight === "number"
          ? Math.round((convertedWeight % 1) * 14).toString()
          : "",
    },
    validate: profileFormValidationFields,
  });
  const { errors } = fetcher.data ?? { errors: {} };
  const allErrors: {
    [K in ProfileFormFields]?: string;
  } & {
    misc?: string;
  } = fetcher.state === "submitting" ? {} : { ...errors, ...form.errors };
  const isFormDirty = form.isDirty();
  const hasErrors = Object.keys(allErrors).length > 0;

  const handleSubmit = (e?: FormEvent) => {
    e?.preventDefault();
    form.clearErrors();

    const { hasErrors: localErrors } = form.validate();

    if (!localErrors && formRef?.current) {
      const formData = new FormData(formRef.current);

      if (preferred_depth_unit === "feet") {
        const feetNum = Number(feet) || 0;
        const inchesNum = Number(inches) || 0;

        const height = feetNum + inchesNum / 12;
        formData.set("height", height.toString());
      }

      if (preferred_weight_unit === "pounds") {
        const stoneNum = Number(form.values?.stone) || 0;
        const poundsNum = Number(form.values?.pounds) || 0;

        const weight = stoneNum * 14 + poundsNum;
        formData.set("weight", weight.toString());
      }

      if (form.values.dob) {
        formData.set("dob", getDateOnly(form.values.dob) ?? "");
      }

      formData.set("preferred_depth_unit", preferred_depth_unit);
      formData.set("preferred_weight_unit", preferred_weight_unit);

      fetcher.submit(formData, {
        method: "post",
        action: "/upsert-profile",
      });
    }
  };

  const feet = form.values?.feet;
  const inches = form.values?.inches;

  useEffect(() => {
    setIsFormDirty(isFormDirty);
  }, [setIsFormDirty, isFormDirty]);

  useEffect(
    function onSuccess() {
      if (
        fetcher.state === "idle" &&
        fetcher.data?.success &&
        !hasErrors &&
        isFormDirty
      ) {
        if (isOnboarding) {
          showNotication("Profile successfully created", "tada", true);
        } else {
          showNotication("Profile successfully updated", "success", true);
        }

        onClose();

        if (!profileId && userProfile.id) {
          navigate(`${PROFILE}/${userProfile.id}`);
          revalidate();
        }
      }
    },
    [
      hasErrors,
      isFormDirty,
      fetcher.state,
      fetcher.data,
      onClose,
      profileId,
      navigate,
      userProfile,
      revalidate,
      isOnboarding,
    ]
  );

  const { setFieldValue } = form;

  const handleSetCountryFields = useCallback(
    (countryId: string, countryCode: string) => {
      if (userProfile?.country_id !== countryId) {
        setFieldValue("country_id", countryId);
        setFieldValue("country_code", countryCode);
      }
    },
    [setFieldValue, userProfile]
  );

  const handleResetCountryFields = useCallback(() => {
    setFieldValue("country_code", "");
    setFieldValue("country_id", "");
  }, [setFieldValue]);

  return (
    <Form onSubmit={handleSubmit} ref={formRef}>
      <Container
        footer={
          !showLeaveWarning ? (
            <>
              <Row flexEnd>
                {isOnboarding && showLeaveWarning === null && (
                  <Button
                    variant="outline"
                    mr="auto"
                    leftSection={<IconArrowLeft size={14} />}
                    onClick={() => setCurrentModal(MODALS.PREFERENCES)}
                  >
                    Back
                  </Button>
                )}
                <Button
                  variant="default"
                  loading={fetcher.state === "submitting"}
                  disabled={fetcher.state === "submitting" || !isFormDirty}
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  Save
                </Button>
              </Row>
              <ErrorText>{allErrors.misc}</ErrorText>
            </>
          ) : null
        }
      >
        {!showLeaveWarning ? (
          <>
            <Row alignTop>
              <TextInput
                required
                name="name"
                label="Name"
                placeholder="Enter your name"
                {...form.getInputProps("name")}
                error={allErrors.name}
              />
              <TextInput
                required
                name="username"
                label="Username" // Todo: make lowercase and strip spaces. Same for emails!
                placeholder="Enter a username"
                {...form.getInputProps("username")}
                onChange={(e) => {
                  form
                    .getInputProps("username")
                    .onChange(e.target.value.toLowerCase());
                }}
                error={allErrors.username}
              />
              <Popover
                text={
                  "Your name, username and country are the only pieces of your profile that are public. Everything else is private, but may be used for analytical purposes."
                }
              />
            </Row>
            <DateInput
              required
              name="dob"
              label="Date of birth"
              placeholder="Select your date of birth"
              maxDate={new Date()}
              defaultLevel={userProfile?.dob ? "month" : "decade"}
              {...form.getInputProps("dob")}
              error={allErrors.dob}
            />
            <CountrySearch
              value={form.values.country_name ?? ""}
              onChange={(v) =>
                form.setValues({
                  country_name: v,
                })
              }
              onSetCountryFields={handleSetCountryFields}
              onResetCountryFields={handleResetCountryFields}
              countryIdProps={{
                ...form.getInputProps("country_id"),
                name: "country_id",
              }}
              error={allErrors.country_id || allErrors.country_name}
            />
            <LocationSearchClient
              disabled={!form.values.country_code}
              filter={form.values.country_code}
              locationInputProps={{
                ...form.getInputProps("location"),
                name: "location",
                label: "Location",
                placeholder: "Enter your location",
              }}
              placeIdInputProps={{
                ...form.getInputProps("place_id"),
                name: "place_id",
              }}
              error={allErrors.location || allErrors.place_id}
            />
            <SegmentedControl
              label="Gender"
              data={[
                { value: "male", label: "Male" },
                { value: "female", label: "Female" },
                { value: "non-binary", label: "Non-binary" },
                { value: "other", label: "Other" },
              ]}
              name="gender"
              {...form.getInputProps("gender")}
              error={allErrors.gender}
              defaultValue=""
            />
            <Row alignTop>
              <HeightFields
                preferred_depth_unit={preferred_depth_unit}
                error={allErrors.height}
                form={form}
              />
              <WeightFields
                preferred_weight_unit={preferred_weight_unit}
                error={allErrors.height}
                form={form}
              />
            </Row>
          </>
        ) : (
          <>
            <Title
              order={2}
              size="h3"
              style={{
                textAlign: "center",
                marginBottom: 20,
              }}
            >
              You have unsaved changes. Are you sure you want to leave?
            </Title>
            <Row>
              <Button
                variant="light"
                style={{ flex: 1 }}
                onClick={() => {
                  setShowLeaveWarning(null);
                }}
                disabled={fetcher.state === "submitting"}
              >
                No thanks
              </Button>
              <Button
                variant="default"
                style={{ flex: 1 }}
                onClick={onClose}
                loading={fetcher.state === "submitting"}
                disabled={fetcher.state === "submitting"}
              >
                Yes please
              </Button>
            </Row>
          </>
        )}
      </Container>
    </Form>
  );
}

export function ProfileModal({
  modalHistory,
  setCurrentModal,
  userProfile,
}: {
  modalHistory: ModalTypes[];
  setCurrentModal: (modal: ModalTypes | null) => void;
  userProfile: UserProfile;
}) {
  const [showLeaveWarning, setShowLeaveWarning] = useState<"close" | null>(
    null
  );
  const [isFormDirty, setIsFormDirty] = useState(false);

  const handleClose = () => {
    setCurrentModal(null);
    setTimeout(() => {
      setShowLeaveWarning(null);
    }, 200);
  };

  const handleCheckClose = () => {
    if (!isFormDirty) {
      handleClose();
    } else {
      setShowLeaveWarning("close");
    }
  };

  const isOnboarding = !userProfile?.username;

  return (
    <WizardModal
      modalHistory={modalHistory}
      modalType={MODALS.EDIT_PROFILE}
      onClose={handleCheckClose}
      titleIcon={<IconUserCircle />}
      title={userProfile?.username ? "Edit profile" : "Create profile"}
      withCloseButton={!isOnboarding || showLeaveWarning !== null}
      isShort
    >
      <ProfileModalForm
        showLeaveWarning={showLeaveWarning}
        setShowLeaveWarning={setShowLeaveWarning}
        onClose={handleClose}
        setIsFormDirty={setIsFormDirty}
        userProfile={userProfile}
        setCurrentModal={setCurrentModal}
        isOnboarding={isOnboarding}
      />
    </WizardModal>
  );
}
