import { Popover as MantinePopover, Text, Button } from "@mantine/core";
import { IconInfoCircle } from "@tabler/icons-react";
import * as classes from "./Popover.css";

export function Popover({ text }: { text: string }) {
  return (
    <MantinePopover width={200} position="bottom" withArrow={false} shadow="md">
      <MantinePopover.Target>
        <Button
          variant="subtle"
          style={{
            marginTop: 24,
            padding: "0 6px",
          }}
        >
          <IconInfoCircle />
        </Button>
      </MantinePopover.Target>
      <MantinePopover.Dropdown
        classNames={{
          dropdown: classes.popover,
          arrow: classes.arrow,
        }}
      >
        <Text size="xs" className={classes.text}>
          {text}
        </Text>
      </MantinePopover.Dropdown>
    </MantinePopover>
  );
}
